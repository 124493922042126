<form class="filter-form-sm">
    <div class="cols">
        <div class="col-2">
            <mat-form-field>
                <input matInput #filterCodeInput (keyup)="filter(filterCodeInput.value, 'Code')" spellcheck="false" [placeholder]="'programs_lblCode' | translate">
                <button mat-button *ngIf="filterCodeInput.value" matSuffix mat-icon-button [title]="'lblClear' | translate"
                        (click)="filterCodeInput.value = ''; filter('', 'Code')">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>

        <div class="col-4">
            <mat-form-field>
                <input matInput #filterTitleInput (keyup)="filter(filterTitleInput.value, 'Title')" spellcheck="false" [placeholder]="'programs_lblTitle' | translate">
                <button mat-button *ngIf="filterTitleInput.value" matSuffix mat-icon-button [title]="'lblClear' | translate"
                        (click)="filterTitleInput.value = ''; filter('', 'Title')">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>
    </div>
</form>

<div class="grid">
    <table class="table table-sticky tight">
        <thead>
            <tr>
                <ng-container *ngFor="let n of columns; let i = index">
                    <th [class.sticky]="n.sticky && (i == 0 || i == columns.length - 1)"
                        [class.sorts]="n.sort"
                        [title]="n.title ? (n.title | translate) : ''"
                        (click)="sort(n)">
                        {{n.title ? (n.title | translate) : ''}}
                        <span *ngIf="n.isSorted" class="sorted-{{n.isSorted}}"></span>
                    </th>
                </ng-container>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let n of rows">
                <td class="sticky">{{n.Code}}</td>
                <td><div class="text-ellipsis" style="width: 200px;" [title]="n.Faculty">{{n.Faculty}}</div></td>
                <td><div class="text-ellipsis" style="width: 200px;" [title]="n.Title">{{n.Title}}</div></td>
                <td>{{n.Financing}}</td>
                <td>{{n.Location}}</td>
                <td>{{n.Form}}</td>
                <td>{{n.PricePerYear}}</td>
                <td>{{n.StartYear}}</td>
                <td>{{n.Language}}</td>
                <td>{{n.LengthInYears}}</td>
                <td>{{n.Quota}}</td>
                <td *ngIf="isResidency">{{n.IsWorkplaceRequired ? n.TotalWorkplaceQuota : ''}}</td>
                <td>
                    <a href="{{n.Url}}" target="_blank" [title]="'programs_lblUrl' | translate" *ngIf="n.Url">
                        <i class="fa fa-external-link"></i>
                    </a>
                </td>
                <td>{{n.NotificationCode}}</td>
                <td>{{n.Speciality}}</td>
                <td>{{n.Degree}}</td>
                <td>
                    <i class="fa fa-check" *ngIf="n.AllowProlongation"></i>
                </td>
                <td>
                    <i class="fa fa-check" *ngIf="n.AllowAdmission"></i>
                </td>
                <td>
                    <i class="fa fa-check" *ngIf="n.AllowApplicantToApply"></i>
                </td>
                <td class="sticky">
                    <div class="table-buttons">
                        <button (click)="edit(n)" type="button" class="btn-icon" [title]="'edit' | translate">
                            <i class="fa fa-pencil"></i>
                        </button>
                        <a [routerLink]="['/programs', n.Id, 'exams'] | localize" class="btn-icon" [title]="'exams_title' | translate">
                            <i class="fa fa-list"></i>
                        </a>
                        <a [routerLink]="['/programs', n.Id, 'results'] | localize" class="btn-icon" [title]="'programResults_title' | translate">
                            <i class="fa fa-list-ol"></i>
                        </a>
                        <a [routerLink]="['/programs', n.Id, 'interviewers'] | localize" class="btn-icon" [title]="'editInterviewList' | translate">
                            <i class="fa fa-clipboard"></i>
                        </a>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="table-total">
    <strong>{{rows.length || 0}}</strong> {{'gridTotal' | translate}}
</div>
