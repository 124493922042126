export const environment = {
    production: true,
    apiUrl: '/service/api',
    showDebugMsg: false,
    minPasswordLength: 9,
    maxPasswordLength: 16,
    showLanguageSwitch: false,
    reCaptchaSiteKey: '6LeP5FoUAAAAAMAKgUhSJiqZ-tZEdadunLnU1Ivk',
    minDate: new Date(1900, 0, 1),
    maxDate: new Date(2154, 11, 31),
    defaultLanguage: 'lv',
    isHomines: false,
    isUnitedRZ: true,
    quizzUrl: '/quizz',
    adfsUrl: '/adfs',
    dvsDocumentLink: 'https://dvs.rsu.lv/Portal/Documents/Update/'
};
