import { Component, Inject } from '@angular/core';

import { AppService } from '../../services/app.service';
import { ApplicationService } from '../../services/application.service';

import { IApplicationProgramWorkplace } from '../../models/Application';

import { IProgramWorkplace } from '../../models/Program';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ISelectedWorkplace {
    Id: string;
    Name: string;
    Priority: number;
}

@Component({
    selector: 'app-application-workplace-select',
    templateUrl: './workplace-select.component.html',
    styleUrls: ['./workplace-select.component.css']
})
export class ApplicationWorkplaceSelectComponent {
    constructor(
        private app: AppService,
        private service: ApplicationService,
        private dialogRef: MatDialogRef<ApplicationWorkplaceSelectComponent>,
        @Inject(MAT_DIALOG_DATA) data: {
            applicationId: number,
            programId: number,
            available: IProgramWorkplace[],
            selected: IApplicationProgramWorkplace[],
            isReadOnly: boolean
        }
    ) {
        this.isReadOnly = data.isReadOnly;
        this.applicationId = data.applicationId;
        this.programId = data.programId;
        this.selectedWorkplaces = data?.selected || [];
        
        if (this.isReadOnly) return;
        
        const availableWorkplaces = data?.available || [];

        if (!this.selectedWorkplaces.length) {
            this.selectedWorkplaces = availableWorkplaces.map((workplace, index) => ({
                Id: workplace.Id,
                Name: workplace.Name,
                Priority: index + 1
            }));
        } else {
            const availableWorkplaceIds = availableWorkplaces.map(t => t.Id);
            // Izvēlētās iestādes var būt tikai no pieejamām
            this.selectedWorkplaces = this.selectedWorkplaces.filter(workplace => availableWorkplaceIds.includes(workplace.Id));

            // Pievieno potenciāli trūkstošās iestādes no pieejamām
            if (this.selectedWorkplaces.length < availableWorkplaces.length) {
                const selectedWorkplaceIds = this.selectedWorkplaces.map(t => t.Id);
                
                availableWorkplaces.forEach(availableWorkplace => {
                    if (!selectedWorkplaceIds.includes(availableWorkplace.Id)) {
                        this.selectedWorkplaces.push({
                            Id: availableWorkplace.Id,
                            Name: availableWorkplace.Name,
                            Priority: this.selectedWorkplaces.length + 1
                        });
                    }
                });
            }
        }
    }

    readonly isReadOnly: boolean;
    readonly applicationId: number;
    readonly programId: number;

    selectedWorkplaces: ISelectedWorkplace[] = [];

    moveUp(workplace: ISelectedWorkplace) {
        const index = this.selectedWorkplaces.indexOf(workplace);

        if (index > 0) {
            let a = this.selectedWorkplaces[index];
            let b = this.selectedWorkplaces[index - 1];
            b.Priority++;
            a.Priority--;
            this.selectedWorkplaces[index] = b;
            this.selectedWorkplaces[index - 1] = a;
        }
    }

    moveDown(workplace: ISelectedWorkplace) {
        let index = this.selectedWorkplaces.indexOf(workplace);

        if (index < this.selectedWorkplaces.length - 1) {
            let a = this.selectedWorkplaces[index];
            let b = this.selectedWorkplaces[index + 1];
            b.Priority--;
            a.Priority++;
            this.selectedWorkplaces[index] = b;
            this.selectedWorkplaces[index + 1] = a;
        }
    }

    isSelected(workplaceId: string): boolean {
        return this.selectedWorkplaces.some(t => t.Id == workplaceId);
    }

    submit() {
        const payload = this.selectedWorkplaces.map(t => t.Id);
        const loading = this.app.showLoading();

        this.service.saveProgramWorkplaces(this.applicationId, this.programId, payload).subscribe(result => {
            this.app.hideLoading(loading);
            this.app.notify(this.app.translate.instant('applications_programWorkplacesSaved'));
            this.dialogRef.close(result);
        }, err => this.app.showSaveError(err));
    }
}
