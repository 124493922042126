<section class="block">
    <header class="block-header">
        <h1 [translate]="'editInterviewList'"></h1>
    </header>
    <ng-container>
        <ngx-datatable #grid
                       [rows]="gridItems"
                       [headerHeight]="'auto'"
                       [footerHeight]="'auto'"
                       [rowHeight]="'auto'"
                       [columnMode]="'force'"
                       [loadingIndicator]="gridLoading"
                       [externalSorting]="false"
                       [messages]="{ emptyMessage: 'gridEmpty' | translate, totalMessage: 'gridTotal' | translate }"
                       (sort)="onGridSort($event)">
            <ngx-datatable-column [name]="'interviewers_lblsignaturename' | translate" prop="SignatureName">
                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.SignatureName">
                    <span *ngIf="!isGridEditing(row)">
                        {{value}}
                    </span>
                    <mat-form-field *ngIf="isGridEditing(row)">
                        <input matInput
                               required
                               name="SignatureName"
                               [(ngModel)]="row.SignatureName"                               
                               maxlength="500" />
                    </mat-form-field>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [name]="'interviewers_lbldesignation' | translate" prop="ShortName">
                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.ShortName">
                    <span *ngIf="!isGridEditing(row)">
                        {{value}}
                    </span>
                    <mat-form-field *ngIf="isGridEditing(row)">
                        <input matInput
                               required
                               name="ShortName"
                               [(ngModel)]="row.ShortName"                               
                               maxlength="30" />
                    </mat-form-field>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [name]="'interviewers_lblpersoncode' | translate" prop="PersonCode">
                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="row.PersonCode">
                    <span *ngIf="!isGridEditing(row)">
                        {{value}}
                    </span>
                    <mat-form-field *ngIf="isGridEditing(row)">
                        <input matInput
                               required
                               name="PersonCode"
                               [(ngModel)]="row.PersonCode"
                               pattern="^[0-9]{6}-?[0-9]{5}$" maxlength="12" />
                    </mat-form-field>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false">
                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                    <button (click)="editGridRow(rowIndex)" class="btn-icon" *ngIf="!isGridEditing(row)" [title]="'edit' | translate">
                        <i class="fa fa-pencil"></i>
                    </button>
                    <button (click)="saveGridRow(rowIndex)"
                            class="btn-icon"
                            *ngIf="isGridEditing(row)"
                            [disabled]="!isValidGridItem(row)"
                            [title]="'save' | translate">
                        <i class="fa fa-floppy-o"></i>
                    </button>
                    <button (click)="cancelGridRow(rowIndex)" class="btn-icon" *ngIf="isGridEditing(row)" [title]="'cancel' | translate">
                        <i class="fa fa-ban"></i>
                    </button>
                    <button (click)="deleteGridRow(rowIndex)" class="btn-icon" *ngIf="!isNewGridItem(row)" [title]="'delete' | translate">
                        <i class="fa fa-trash"></i>
                    </button>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
        <div>
            <button mat-raised-button color="primary" (click)="addGridRow()">
                <i class="fa fa-plus"></i>
                {{'add' | translate}}
            </button>
        </div>
    </ng-container>
</section>
