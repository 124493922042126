import { Observable, of } from 'rxjs';

import { AppService } from './services/app.service';

export class GridComponentBase<T> {
    constructor(public app: AppService) { }

    gridItems: T[] = [];
    gridLoading: boolean;

    protected gridPropertyMapping: { [key: string]: string } = {};
    protected gridEditRows: T[] = [];
    protected gridNewRows: T[] = [];
    protected gridRowOriginals: { [key: number]: T } = {};

    editGridRow(rowIndex: number) {
        this.gridRowOriginals[rowIndex] = Object.assign(this.createEmptyObject(), this.gridItems[rowIndex]);
        this.onBeforeEditGridRow(this.gridItems[rowIndex]);
        this.gridEditRows.push(this.gridItems[rowIndex]);
    }

    protected onBeforeEditGridRow(row: T) {}

    saveGridRow(rowIndex: number) {
        let item = this.gridItems[rowIndex];

        if (this.isValidGridItem(item)) {
            let indexOfNew = this.gridNewRows.indexOf(item);

            const loading = this.app.showLoading();
            const isNew = indexOfNew !== -1;
            const request = isNew ? this.createGridItem(item) : this.updateGridItem(item);

            request.subscribe(data => {
                this.app.hideLoading(loading);
                this.endGridRowEditing(rowIndex);
                indexOfNew !== -1 && this.gridNewRows.splice(indexOfNew, 1);
                Object.assign(item, data);
                this.refreshGrid();
            }, err => {
                this.app.hideLoading(loading);
                this.onGridError(err, isNew ? 'create' : 'update', this);
            });
        }
    }

    deleteGridRow(rowIndex: number, event) {
        this.app.confirm(this.app.translate.instant('confirmItemDelete'), result => {
            if (!result)
                return;

            const loading = this.app.showLoading();
            const item = this.gridItems[rowIndex];

            this.deleteGridItem(item).subscribe(data => {
                this.app.hideLoading(loading);
                this.endGridRowEditing(rowIndex);
                this.gridItems.splice(rowIndex, 1);
            }, err => {
                this.app.hideLoading(loading);
                this.onGridError(err, 'delete', this);
            });
        });
    }

    addGridRow() {
        let item = this.createEmptyObject();

        this.gridItems.push(item);
        this.gridNewRows.push(item);

        this.refreshGrid();

        this.editGridRow(this.gridItems.length - 1);
    }

    cancelGridRow(rowIndex: number) {
        if (this.isNewGridItem(this.gridItems[rowIndex])) {
            this.gridItems.splice(rowIndex, 1);
            this.refreshGrid();
        } else {
            this.endGridRowEditing(rowIndex);
            Object.assign(this.gridItems[rowIndex], this.gridRowOriginals[rowIndex]);
        }
    }

    isValidGridItem(item: T): boolean {
        return true;
    }

    isNewGridItem(item: T): boolean {
        return this.gridNewRows.indexOf(item) > -1;
    }

    isGridEditing(item: T): boolean {
        return this.gridEditRows.indexOf(item) > -1;
    }

    onGridSort(event) {
        const sort = event.sorts[0];
        const isDesc = sort.dir === 'desc';

        let exRows = [];
        let newRows = [];

        for (let i = 0; i < this.gridItems.length; i++) {
            if (this.isNewGridItem(this.gridItems[i]))
                newRows.push(this.gridItems[i]);
            else
                exRows.push(this.gridItems[i]);
        }

        let comparator = (a, b) => {
            let valueA = (a[sort.prop] || '').toString();
            let valueB = (b[sort.prop] || '').toString();

            return event.column && event.column.comparator
                ? event.column.comparator(valueA, valueB, isDesc)
                : valueA.localeCompare(valueB) * (isDesc ? -1 : 1);
        }

        exRows.sort(comparator);

        // Always put new rows at the end
        const allRows = exRows.concat(newRows);
        this.gridItems = allRows;
    }

    gridNumberComparator(valueA, valueB, isDesc: boolean) {
        return isDesc ? (+valueB) - (+valueA) : (+valueA) - (+valueB);
    }

    protected createGridItem(item: T): Observable<any> {
        return of(item);
    }

    protected updateGridItem(item: T): Observable<any> {
        return of(item);
    }

    protected deleteGridItem(item: T): Observable<any> {
        return of(item);
    }

    protected createEmptyObject(): T {
        return <T>{};
    }

    protected refreshGrid() {
        this.gridItems = [...this.gridItems];
    }

    protected showGridLoading() {
        this.gridLoading = true;
    }

    protected hideGridLoading() {
        setTimeout(() => { this.gridLoading = false }, 500);
    }

    protected onGridError(error: any, event: string, self: GridComponentBase<T>) {
        console.log('super.onGridError');
        self.hideGridLoading();

        switch (event) {
            case 'create':
            case 'update': this.app.showSaveError(error); break;
            case 'delete': this.app.showDeleteError(error); break;
            default: this.app.alerts.error(this.app.translate.instant('unknownError'));
        }
    }

    protected endGridRowEditing(rowIndex: number) {
        let index = this.gridEditRows.indexOf(this.gridItems[rowIndex]);

        if (index > -1)
            this.gridEditRows.splice(index, 1);
    }

    protected selectedRows: T[] = [];

    selectRow(item: T) {
        let index = this.selectedRows.indexOf(item);

        if (index > -1)
            this.selectedRows.splice(index, 1);
        else
            this.selectedRows.push(item);
    }

    selectRows(event) {
        if (event.checked)
            this.selectedRows = [...this.gridItems];
        else
            this.selectedRows = [];
    }

    rowSelected(item: T): boolean {
        return this.selectedRows.indexOf(item) > -1;
    }

    allRowsSelected(): boolean {
        return this.selectedRows.length === this.gridItems.length;
    }


}
