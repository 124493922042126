import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { Program } from '../models/Program';

import { ProgramService } from '../services/program.service';
import { AppService } from '../services/app.service';

import { ProgramEditComponent } from './program-edit.component';
import { MatDialog } from '@angular/material/dialog';
import { Utils } from '../Utils';

interface IColumn {
    title?: string;
    sticky?: boolean;
    sort?: string | ((a: Program, b: Program) => number);
    isSorted?: 'asc' | 'desc';
    key?: string;
}

@Component({
    selector: 'app-admission-programs',
    templateUrl: './programs.component.html',
    styleUrls: ['./programs.component.css']
})
export class ProgramsComponent implements OnInit {
    constructor(
        private app: AppService,
        private service: ProgramService,
        private dialog: MatDialog
    ) { }

    @Input() admissionId: number;
    @Input() isResidency: boolean;

    rows: Program[] = [];

    readonly columns: IColumn[] = [
        { title: 'programs_lblCode', sticky: true, sort: 'Code' },
        { title: 'programs_lblFaculty', sort: 'Faculty' },
        { title: 'programs_lblTitle', sort: 'Title' },
        { title: 'programs_lblFinancing', sort: 'Financing' },
        { title: 'programs_lblLocation', sort: 'Location' },
        { title: 'programs_lblStudyType', sort: 'Form' },
        { title: 'programs_lblPricePerYear', sort: 'PricePerYear' },
        { title: 'programs_lblStartYear', sort: 'StartYear' },
        { title: 'programs_lblLanguage', sort: 'Language' },
        { title: 'programs_lblLengthInYears', sort: 'LengthInYears' },
        { title: 'programs_lblQuota', sort: 'Quota', key: 'quota' },
        { title: 'programs_lblUrl', sort: 'Url' },
        { title: 'programs_lblNotificationCode', sort: 'NotificationCode' },
        { title: 'programs_lblSpeciality', sort: 'Speciality' },
        { title: 'programs_lblDegree', sort: 'Degree' },
        { title: 'programs_lblAllowProlongation', sort: 'AllowProlongation' },
        { title: 'programs_lblAllowAdmission', sort: 'AllowAdmission' },
        { title: 'programs_lblAllowApplicantToApply', sort: 'AllowApplicantToApply' },
        { sticky: true }
    ];

    private allRows: Program[] = [];
    private filterCodeTerms = new Subject<string>();
    private filterTitleTerms = new Subject<string>();

    ngOnInit() {
        if (this.isResidency) {
            const quotaIx = this.columns.findIndex(t => t.key == 'quota');

            if (quotaIx > -1) {
                this.columns.splice(quotaIx + 1, 0, {
                    title: 'programs_lblWorkplaceQuota',
                    sort: (a, b) =>  (a.IsWorkplaceRequired ? a.TotalWorkplaceQuota : -1) - (b.IsWorkplaceRequired ? b.TotalWorkplaceQuota : -1)
                });
            }
        }

        this.filterCodeTerms.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(term => this.applyFilter(term));

        this.filterTitleTerms.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(term => this.applyFilter(term));

        this.fetch();
    }

    edit(item: Program) {
        let dialogRef = this.dialog.open(ProgramEditComponent, {
            disableClose: true,
            width: '800px',
            data: {
                program: Object.assign(new Program(), item),
                workplacesEnabled: this.isResidency
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                const loading = this.app.showLoading();
                this.service.update(item.Id, {
                    AllowAdmission: result.AllowAdmission,
                    AllowApplicantToApply: result.AllowApplicantToApply,
                    AllowProlongation: result.AllowProlongation,
                    IsWorkplaceRequired: result.IsWorkplaceRequired,
                    NotificationCode: result.NotificationCode,
                    Quota: result.Quota,
                    Title: result.Title,
                    Workplaces: result.Workplaces
                }).subscribe(data => {
                    Object.assign(item, data);
                    this.app.hideLoading(loading);
                }, err => this.app.showSaveError(err));
            }
        });
    }

    filter(term: string, field: string) {
        switch (field) {
            case 'Code': this.filterCodeTerms.next(term); break;
            case 'Title': this.filterTitleTerms.next(term); break;
        }
    }

    load() {
        this.fetch();
    }

    sort(column: IColumn) {
        if (column.sort) {
            const dir = column.isSorted == undefined || column.isSorted == 'desc' ? 'asc' : 'desc';

            this.columns.forEach(t => (t.isSorted = undefined));
            column.isSorted = dir;

            Utils.sort(this.rows, column.sort, dir);
        }
    }

    private fetch() {
        const loading = this.app.showLoading();
        if (this.admissionId) {
            this.service.get(this.admissionId).subscribe(data => {
                this.rows = data;
                this.allRows = [...data];
                this.app.hideLoading(loading);
            }, err => this.app.showLoadError(err));
        }
    }

    private applyFilter(term: string) {
        if (!term) {
            this.rows = this.allRows;
        } else {
            const val = term.toLowerCase();
            let temp = this.allRows.filter(t => {
                return (t.Code || '').toLowerCase().indexOf(val) > -1 || (t.Title || '').toLowerCase().indexOf(val) > -1;
            });

            this.rows = temp;
        }
    }
}
