import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AuthService } from './auth.service';
import { environment as ENV } from '../../environments/environment';
import { UserInfo } from '../models/UserInfo';
import {
    AdmissionInterviewSummary, ApplicationInterviewSummary, ScheduledDate, InterviewStatus, InterviewScheduleConflict,
    InterviewData, Questionnaire, ScheduledInterviewItem
} from '../models/Interview';
import { DateTime } from 'luxon';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ApplicationNote } from '../models/Application';

/**
 * Interview service
 */
@Injectable()
export class InterviewService {
    constructor(private http: HttpClient, private auth: AuthService) {
        if (!!this.auth.user)
            this.auth.user.subscribe(user => { this.update(user); });
    }

    private get apiUrl(): string { return `${ENV.apiUrl}/interviews` }

    private userRoleSubject = new BehaviorSubject<string>('');
    get userRole() {
        return this.userRoleSubject.asObservable();
    }

    private _currentRole: string;
    get currentRole(): string {
        return this._currentRole;
    }

    private prevSessionKey: string = null;

    private async update(user: UserInfo): Promise<void> {
        if (!user || !user.sessionKey) {
            this.setRole('');
            this.prevSessionKey = null;
            return;
        }
        if (user.sessionKey === this.prevSessionKey)
            return;

        if (this.auth.isAdmin(user)) {
            this.setRole('Administrator');
        }
        else if (this.auth.isPowerUser(user)) {
            this.setRole('PowerUser');
        }
        else {
            const role = await this.getUserRole();
            this.setRole(role);
        }
        this.prevSessionKey = user.sessionKey;
    }

    private setRole(role: string) {
        this._currentRole = role;
        this.userRoleSubject.next(role);
    }

    private getUserRole() : Promise<string> {
        const url = `${this.apiUrl}/userrole`;
        return this.http.get<string>(url).toPromise();
    }

    getQuestionnaire(code: string): Promise<Questionnaire> {
        const url = `${this.apiUrl}/questionnaire?code=${encodeURIComponent(code)}`;
        return this.http.get<Questionnaire>(url).toPromise();
    }

    getScheduledDates(role: string, count?: number): Promise<ScheduledDate[]> {
        let url = `${this.apiUrl}/myscheduleddates?role=${role}`;
        if (count) url += `&count=${count}`;
        return this.http.get<ScheduledDate[]>(url).toPromise();
    }

    async getMyInterviews(forDate: string, role: string): Promise<ScheduledInterviewItem[]> {
        const url = `${this.apiUrl}/myschedule?forDate=${forDate}&role=${role}`;
        const result = await this.http.get<ScheduledInterviewItem[]>(url).toPromise();
        result.forEach(si => {
            si.scheduledTime = DateTime.fromISO(<string>si.scheduledTime);
            si.availableFrom = DateTime.fromISO(<string>si.availableFrom);
        });
        return result;
    }

    getAdmissionInterviewSummaries(admissionId: number): Promise<AdmissionInterviewSummary[]> {
        const url = `${this.apiUrl}/admissionsummary/${admissionId}`;
        return this.http.get<AdmissionInterviewSummary[]>(url).toPromise();
    }

    async getInterviews(programId: number, examId: number): Promise<ApplicationInterviewSummary[]> {
        const url = `${this.apiUrl}/programinterviews/${programId}/${examId}`;
        const result = await this.http.get<ApplicationInterviewSummary[]>(url).toPromise();
        result.forEach((i: ApplicationInterviewSummary) => {
            if (i.InterviewTime && typeof i.InterviewTime === 'string')
                i.InterviewTime = DateTime.fromISO(i.InterviewTime);
            i.ScheduleConflicts.forEach(c => {
                if (c.ConflictingTime && typeof c.ConflictingTime === 'string')
                    c.ConflictingTime = DateTime.fromISO(c.ConflictingTime);
            });
        });
        return result;
    }

    async getScheduleConflicts(programId: number, examId: number): Promise<InterviewScheduleConflict[]> {
        const url = `${this.apiUrl}/scheduleconflicts/${programId}/${examId}`;
        const result = await this.http.get<InterviewScheduleConflict[]>(url).toPromise();
        result.forEach((c: InterviewScheduleConflict) => {
            if (c.ConflictingTime && typeof c.ConflictingTime === 'string')
                c.ConflictingTime = DateTime.fromISO(c.ConflictingTime);
        });
        return result;
    }

    publishResults(interviewGroups: { ProgramId: number, ExamId: number }[]): Promise<void> {
        const apiUrl = `${this.apiUrl}/publishresults`;
        return this.http.post<void>(apiUrl, interviewGroups).toPromise();
    }

    updateSchedules(applicationExamIds: number[], date: string, roomId: string, url: string) : Promise<void> {
        const apiUrl = `${this.apiUrl}/updateschedules`;
        return this.http.post<void>(apiUrl, {
            applicationExamIds: applicationExamIds,
            date: date,
            roomId: roomId,
            url: url
        }).toPromise();
    };

    clearSchedules(applicationExamIds: number[]) : Promise<void> {
        const apiUrl = `${this.apiUrl}/updateschedules`;
        return this.http.post<void>(apiUrl, { applicationExamIds: applicationExamIds }).toPromise();
    };

    async setInterviewTime(applicationExamId: number, interviewTime: DateTime) : Promise<InterviewScheduleConflict[]> {
        const apiUrl = `${this.apiUrl}/${applicationExamId}/settime`;
        const result = await  this.http.post<InterviewScheduleConflict[]>(apiUrl, interviewTime).toPromise();
        result.forEach(c => {
            if (c.ConflictingTime && typeof c.ConflictingTime === 'string')
                c.ConflictingTime = DateTime.fromISO(c.ConflictingTime);
        });
        return result;
    }

    getInterviewData(applicationExamId: number, interviewerId: number): Promise<InterviewData> {
        const url = `${this.apiUrl}/${applicationExamId}/${interviewerId}`;
        return this.http.get<InterviewData>(url).toPromise();
    }

    getHistory(applicationExamId: number, interviewerId: number): Promise<any[]> {
        const url = `${this.apiUrl}/${applicationExamId}/${interviewerId}/history`;
        return this.http.get<any[]>(url).toPromise();
    }

    updateSelectedGrades(applicationExamId: number, interviewerId: number, selectedGrades: { [entryCode: string] : number }): Promise<number> {
        const url = `${this.apiUrl}/${applicationExamId}/${interviewerId}`;
        return this.http.post<number>(url, selectedGrades).toPromise();
    }

    setStatus(applicationExamId: number, interviewerId: number, status: InterviewStatus): Promise<void> {
        let url = `${this.apiUrl}/${applicationExamId}/`;
        if (interviewerId) url += `${interviewerId}/`;
        url += 'status';
        return this.http.post<void>(url, JSON.stringify(status), { headers: {'Content-Type': 'application/json'} }).toPromise();
    }

    sendInterviewEmails(args: {applicationExamIds: number[], subject: string, notificationCode: string}) {
        const url = `${this.apiUrl}/programinterviews/sendemails`;
        return this.http.post(url, args).pipe(map(t => t));
    }

    //async deleteInterviewEmails(args: {applicationExamIds: number[]}) : Promise<any> {
    //    const url = `${this.apiUrl}/programinterviews/deleteemails`;
    //    return this.http.post<void>(url, args).toPromise();
    //}

    getEmailText = (applicationId: number, applicationExamId: number) : Promise<ApplicationNote[]> => {
        const url = `${this.apiUrl}/getnotes/${applicationId}/${applicationExamId}`;
        return this.http.get<ApplicationNote[]>(url).toPromise();
    }

    getDocumentDownloadUrl(documentId: number): string {
        return `${this.apiUrl}/documents/${documentId}?SessionKey=${this.auth.sessionKey}`;
    }
}
