<header class="content-header">
    <h1 class="clearfix" [translate]="'interview_heading'"></h1>
</header>
<form #form="ngForm">
<section class="block" *ngIf="data">
    <header class="block-header">
        <h1>
            {{ data.ExamTitle }}
            <a [routerLink]="'history'" *ngIf="isPowerUser">
                <i class="fa fa-history" aria-hidden="true"></i>
            </a>
        </h1>
    </header>
    <div style="display: flex; justify-content: space-between; margin-bottom: 2px;">
        <a [href]="data.InterviewOnlineUrl" target="_blank" class="btn-inline" mat-raised-button color="primary">{{ 'interview_join' | translate }}</a>
        <div>
            {{ 'interview_lblProgram' | translate }}: <strong>{{ data.ProgramTitle }}</strong>
        </div>
        <div>
            {{ 'interview_lblApplicant' | translate }}: <strong>{{ data.ApplicantName }}</strong>
        </div>
        <div>
            <button *ngIf="isAdmin && data.Status === InterviewStatus.Completed" type="button" class="btn-inline" mat-raised-button color="accent" (click)="amend()">{{ 'interview_amend' | translate }}</button>
            <button *ngIf="isAdmin && (data.Status === InterviewStatus.InProgress || data.Status === InterviewStatus.Completed)" type="button" class="btn-inline" mat-raised-button color="warn" (click)="reset()">{{ 'interview_reset' | translate }}</button>
        </div>
    </div>
    <ng-container *ngTemplateOutlet="navigationactions"></ng-container>
</section>
<ng-template #navigationactions>
    <div style="display: flex; justify-content: flex-end;">
        <a  [routerLink]="backLink" class="btn-inline" mat-raised-button>{{ 'interview_returnToList' | translate }}</a>
        <button *ngIf="!data.ReadOnly && data.Status !== InterviewStatus.Completed"
            type="button" class="btn-inline" mat-raised-button color="primary" [disabled]="!form.valid" (click)="finish()">
            <i class="fa fa-flag-checkered"></i> {{ 'interview_finish' | translate }}
        </button>
    </div>
</ng-template>
<section class="block" *ngIf="data && questionnaire">
    <header class="block-header">
        <h1>{{ questionnaire.Name }}</h1>
        <aside *ngIf="data.Documents?.length">
            <button mat-raised-button [matMenuTriggerFor]="menu" color="accent">
                <i class="fa fa-download"></i> {{ 'interview_download' | translate }}
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
                <ng-template matMenuContent>
                    <a *ngFor="let item of data.Documents" href="{{getDocumentDownloadUrl(item.Id)}}" target="_blank" mat-menu-item>
                        {{ item.FileName }}
                    </a>
                </ng-template>
            </mat-menu>
        </aside>
    </header>
    <div class="app-questionnaire">
        <ng-container *ngTemplateOutlet="list; context: {$implicit: questionnaire.Entries}"></ng-container>

        <ng-template #list let-entries>
            <ng-container *ngFor="let entry of entries">
                <ng-container *ngIf="entry.Code else textOnly">
                    <ng-container *ngTemplateOutlet="dataEntry; context: {$implicit: entry}"></ng-container>
                </ng-container>
                <ng-template #textOnly>
                    <div class="app-questionnaire-text-only" [ngStyle]="{ 'background-color': entry.Background ? entry.Background : 'inherit' }">
                        <ng-container *ngTemplateOutlet="text; context: { $implicit: entry }"></ng-container>
                    </div>
                    <ng-container *ngIf="entry.Children">
                        <ng-container *ngTemplateOutlet="list; context: {$implicit: entry.Children}"></ng-container>
                    </ng-container>
                </ng-template>
            </ng-container>
        </ng-template>
        <ng-template #dataEntry let-entry>
            <div class="app-questionnaire-label" [ngStyle]="{ 'background-color': entry.Background ? entry.Background : 'inherit' }">
                <ng-container *ngTemplateOutlet="text; context: { $implicit: entry }"></ng-container>
            </div>
            <div class="app-questionnaire-answers" [class.app-questionnaire-required]="data.SelectedGrades[entry.Code] === undefined">
                <mat-radio-group [name]="entry.Code" (change)="answerChanged($event, entry.Code)" [(ngModel)]="data.SelectedGrades[entry.Code]" required [disabled]="data.ReadOnly">
                    <mat-radio-button *ngFor="let answer of questionnaire.AnswerSets[entry.AnswerSet]" [value]="answer.Points">
                        {{ 'interview_answer_option' | translate | formatString: [answer.Text, answer.Points] }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </ng-template>
        <ng-template #text let-entry>
            <span [class.app-questionnaire-highlighted]="entry.IsHighlighted">{{ entry.Text }}</span>
            <button *ngIf="entry.Description" mat-icon-button [matTooltip]="entry.Description" (click)="showDescription(entry.Description)"><i class="fa fa-info-circle" style="color: blue"></i></button>
        </ng-template>

        <div style="grid-column: 1 / -1;"><hr/></div>
        <div class="app-questionnaire-total">{{'interview_lblTotal' | translate}}: <strong>{{data.TotalGrade}}</strong></div>
    </div>
    <ng-container *ngTemplateOutlet="navigationactions"></ng-container>
</section>
</form>
