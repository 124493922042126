import { BaseEntity } from './BaseEntity';
import { Program, ProgramSpecialization } from './Program';
import { Classifier } from './Classifier';

export enum ApplicationStatus {
    New = 'New',
    SubmittedByApplicant = 'SubmittedByApplicant',
    SubmittedByClerk = 'SubmittedByClerk',
    OnHold = 'OnHold',
    Approved = 'Approved',
    Cancelled = 'Cancelled',
    Finished = 'Finished',
    Recalled = 'Recalled',
    RecalledWaiting = 'RecalledWaiting',
    Deleted = 'Deleted'
}

export enum ApplicationUnitedRZStatus {
    SpecialitySelection = 'SpecialitySelection',
    SpecialityApproval = 'SpecialityApproval',
    SpecialityApproved = 'SpecialityApproved',
    UniversitySelection = 'UniversitySelection',
    UniversitySelected = 'UniversitySelected'
}

export enum ApplicationResultType {
    In = 'In',
    InHigher = 'InHigher',
    InLower = 'InLower',
    InManual = 'InManual',
    Out = 'Out',
    OutManual = 'OutManual'
}

export enum AuxType {
    Thesis = 'Thesis',
    Payment='Payment'
}

export enum GradeSystemType {
    FivePoint = 'FivePoint',
    TenPoint = 'TenPoint',
    PercentageHigh = 'PercentageHigh',
    PercentageOptimal = 'PercentageOptimal',
    PercentageBefore2022 = 'PercentageBefore2022'
}

export class ApplicationForContract {
    Id: number;
    Number: string;
    Name: string;
    Surname: string;
    StudiesAtLaterStages: boolean;
    IsForeign: boolean;
    public constructor(app: any) {
        if (app.ApplicationId)
            this.Id = app.ApplicationId;
        else
            this.Id = app.Id;
        this.IsForeign = app.IsForeign;
        this.Name = app.Name;
        this.Surname = app.Surname;
        if (app.Number)
            this.Number = app.Number;
        else
            this.Number = app.ApplicationNumber;
        this.StudiesAtLaterStages = app.StudiesAtLaterStages;
    }
}

export class ApplicationForEnrollment extends ApplicationForContract {
    IsSelected: boolean = true;
    EnrollmentStatus: string = '';
    public constructor(app: any) {
        super(app);
        this.EnrollmentStatus = app.EnrollmentStatus;
        if (this.EnrollmentStatus != 'enrollmentStatus_readyforenrollment')
            this.IsSelected = false;
    }
}
export class EnrollmentOrder extends BaseEntity {
    OrderDate: Date;
    ProtocolNumber: string;
    Status: string;
    ReferenceSignatureTypeId: string;
    ApplicationNumbers: string[];
    ReferenceSignerId: string;
    DvsSignerId: number;
    IsSelectedAutomatically: boolean;
}

export class Application extends BaseEntity {
    Id: number;
    AdmissionId: number;
    AdmissionTitle: string;
    Number: string;
    Date: Date;
    Name: string;
    Surname: string;
    Birthdate: Date;
    PersonCode: string;
    GenderId: string;
    Gender: string;
    Phone: string;
    Email: string;
    DocType: string;
    DocNumber: string;
    DocDate: Date;
    DocExpiry: Date;
    DocIssuer: string;
    DocIssuerId?: string;
    DocBirthPlace: string;
    Status: ApplicationStatus;
    AddrCountry: string;
    AddrCountryId: string;
    AddrApartment: string;
    AddrApartmentId?: string;
    AddrCity: string;
    AddrCityId?: string;
    AddrCounty: string;
    AddrCountyId?: string;
    AddrHouse: string;
    AddrHouseId?: string;
    AddrIndex: string;
    AddrIndexId?: string;
    AddrParish: string;
    AddrParishId?: string;
    AddrStreet: string;
    AddrStreetId?: string;
    AddrText: string;
    AddrVillage: string;
    AddrVillageId?: string;
    ContIsSameAsAddr: boolean;
    ContCountry: string;
    ContCountryId: string;
    ContApartment: string;
    ContApartmentId?: string;
    ContCity: string;
    ContCityId?: string;
    ContCounty: string;
    ContCountyId?: string;
    ContHouse: string;
    ContHouseId?: string;
    ContIndex: string;
    ContParish: string;
    ContParishId?: string;
    ContStreet: string;
    ContStreetId?: string;
    ContText: string;
    ContVillage: string;
    ContVillageId?: string;
    IsForeign: boolean;
    IsHomines: boolean;
    IsRZ: boolean;
    IsUnitedRZ: boolean;
    ShowBanklink: boolean;
    MyInstitutionId: string;
    MyInstitution: string;
    MyInstitutionClassId: string;
    ResponsibleName: string;
    ResponsibleLastName: string;
    ResponsiblePhone: string;
    ResponsibleMail: string;
    Citizenship: string;
    Nationality: string;
    CrmPersonId: string;
    CrmReflectantId: string;
    CrmContractId: string;
    ContractBalanceRefreshDate: Date;
    ContractPaid: number;
    ContractTotal: number;
    ContractIsBudget: boolean;
    PhotoTimestamp: number;
    EduDocApprovalRequired: boolean;
    PersonVerified: string;
    GradesConfirmed: boolean;
    RulesConfirmed: boolean;
    IsSISDataChecked: boolean;
    StudiesAtLaterStages: boolean;
    IsGraduate: boolean;
    IsIncomplete: boolean;
    ProgramInfoNote: string;
    ApplicationForCreditTransfer: boolean;
    UnitedRZStatus: ApplicationUnitedRZStatus;
    PaymentFileUploaded: boolean;
    CrmEnrollmentNumber: string;
    IsSigned: boolean;
    SubmittedByRepresentative: boolean;
    RepresentativeName: string;
    RepresentativeSurname: string;
    RepresentativePersonCode: string;
    IsLateSubmission: boolean;
    HasPreviousPersonData: boolean;
    PreviousName: string;
    PreviousSurname: string;
    PreviousPersonCode: string;
}

export class ApplicationNote {
    Id: number;
    Created: Date;
    ApplicationId: number;
    Position: string;
    Text: string;
}

export enum ApplicationNotePosition {
    PersonData = 'PersonData',
    Programs = 'Programs',
    ProgramInfo = 'ProgramInfo',
	ProgramResult = 'ProgramResult',
    Program = 'Program_',
    Exam = 'Exam_'
}

export interface ViisGrade {
    Name: string;
    Grade?: number;
    RawGrade?: number;
}

export class ApplicationExam {
    Id: number;
    ApplicationId: number;
    ExamId: number;
    Code: string;
    Title: string;
    Description: string;
    Type: string;
    TypeCode: string;
    Grade?: number;
    GradeSystem?: GradeSystemType;
    GradeCoef?: number;
    RawGrade?: number;
    PreApprovalGrade?: number;
    GradeRoundPositions: number;
    ExamMandatory: boolean;
    SortOrder: number;
    Group: string;
    GroupSort: number;
    MinGrade: number;
    MaxGrade: number;
    Weight?: number;
    ProgramId?: number;
    Program: string;
    Reusable: boolean;
    IsHybridDoc: boolean;
    DisplayTypeCode: string;
    Position: string;
    AuxType: string;
    AuxTypes: string[] = [];
    ViisGrades: ViisGrade[] = [];
    CrmGrades: number[] = [];
    SendToDvs: boolean;
}

export class ApplicationAuxData {
    Id: number;
    ApplicationId: number;
    Type: string;
    Data: string;
    Text1: string;
    Text2: string;
    Text3: string;
    Text4: string;
    Text5: string;
    Created: Date;
    Binary: File;
}

export class ApplicationEducation {
    Id: number;
    ApplicationId: number;
    Institution: string;
    InstitutionId: string;
    YearFrom?: number;
    YearTo?: number;
    Level: string;
    LevelId: string;
    DiplomaNumber: string;
    IsSelected: boolean;
    TempId: string;
    EducationAcquiredInId: string;
    ExtSource: string;
    SendFilesToDvs: boolean;
}

export class ApplicationPerson {
    Id: number;
    ApplicationId: number;
    CrmId: string;
    CrmMatchType: string;
    PersonCode: string;
    Name: string;
    Surname: string;
    Email: string;
    LocalEmail: string;
    Phone: string;
    Debt: number;
    DebtInspectionDate: Date;
    IsLocalLinked: boolean;
    LinkedApplications: { Id: number, Number: string }[] = [];
    IsActive: boolean;
}

export class ApplicationPersonDebt {
    Id: number;
    Debt: number;
    DebtInspectionDate: Date;
}

export class ApplicationContractDebt {
    Id: number;
    Total: number;
    Paid: number;
    ContractBalanceRefreshDate: Date;
}
export class ApplicationContractStatus {
    currentStatus: string;
    currentStatusCode: string;
    IsBeingProcessed: boolean;
    ESignedByEmployee: boolean;
    ESignedByApplicant: boolean;
    DvsDocumentId: number;
}

export class ApplicationSearch {
    Id: number;
    Admission: string;
    AdmissionId: number;
    Number: string;
    Name: string;
    Surname: string;
    PersonCode: string;
    Email: string;
    University: Classifier;
    Result: string;
    CreatedBy: { FirstName: string, LastName: string };
}

export class ApplicationProgram {
    Id: number;
    Priority: number;
    Result: string;
    Program: Program;
    Specialization: ProgramSpecialization;
    University: Classifier;
    ContractStatus: string;
    EnrollmentStatus: string;
    HasWorkplace: boolean;
    WorkplaceId: string;
    Workplace: string;
    Rank: string;
    IsBudget: boolean;
    AllowProlongation: boolean;
    VerifiedWorkplace: string;
    VerifiedWorkplaceId: string;
    Workplaces: IApplicationProgramWorkplace[] = [];
}

export interface IApplicationProgramWorkplace {
    Id: string;
    Name: string;
    Priority: number;
}

export class ApplicationProgramPriority {
    ProgramId: number;
    Priority: number;
}

export class ApplicationPersonDocument {
    Id: number;
    ApplicationId: number;
    Type: string;
    Number: string;
    Date: Date;
    Expiry: Date;
    Issuer: string;
    IssuerId?: string;
    IsMain: boolean;
    Citizenship: string;
    CitizenshipId: string;
}

export class ApplicationPreviousUniversity {
    Id: number;
    ApplicationId: number;
    SelectedPrograms: PreviousUniversitySelectedProgram[] = [];
    Name: string;
    Website: string;
    Program: string;
    DegreeTypes: string[] = [];
    DegreeLevels: string[] = [];
    DateFrom: Date;
    DateTo: Date;
    CreditPoints: string;
    Documents: string;
    StudiesConfirmed: boolean;
    DocumentsConfirmed: boolean;
    TransferRulesConfirmed: boolean;
}

export class PreviousUniversitySelectedProgram {
    ProgramId: number;
    ApplicationProgramId: number;
    Title: string;
}

export class ApplicationSchedule extends BaseEntity  {
  DetailId: string;
  SemesterName: string;
  PaymentDueDate: Date;
  PaymentDate: Date;
  PaymentAmount: number;
}

export class ApplicationPaymentSchedule extends BaseEntity {
  //SemesterPayments: number;
  //MonthPayments: number;
  Id: number;
  Semester: number;
  CP: number;
  Payment: number;
  SemesterName: string;
  StartDate: Date;
  IsStart: boolean;
  Year: number;
  PaymentDate: Date;
  DiscountId: string;
  MinPaymentDate: Date;
}
export class ApplicationPaymentResponse {
  MonthPayments: ApplicationSchedule[];
  SemesterPayments: ApplicationPaymentSchedule[];
}

export class ApplicationUpdateCP extends BaseEntity {
  SemesterPayments: number;
  MonthPayments: number;

}

export class ApplicationPaymentList extends BaseEntity {
  DetailId: string;
  SemesterName: string;
  PaymentDueDate: Date;

}

export class ProgramApplicationContract {
    SignerId: string;
    ReasonId: string;
    DiscountId: string;
    MedInstitutionId: string;
    SpecialConditions: string;
    SpecialConditionsEn: string;
    ProgramId:number;
    MinPaymentDate:Date;
    ApplicationId:number;
    ProtocolNr: string;
    ProtocolDate:Date;
    ContractStatus: string;
    TotalCP: number;
    SchedulePaymentStartDate: Date;
    SchedulePaymentEndDate: Date;
    StartYear: number;
    StartSemester: number;
}
export class StudentPayStatusModel {
    PaidReg:boolean;
    PaidFirstPart:boolean;
    PaidRegAmount: number;
    NeedTopPayRegAmount: number;
}
//export class ApplicationPaymentSchedule extends BaseEntity {
//  Semester: number;
//  Month: number;
//  PaymentDueDate: Date;
//}
export class PersonalEmailToApplicantModel {
    ApplicationId: number;
    ApplicationNumber: string;
    Subject: string;
    Body: string;
    Address: string;
    Date: Date;
    Author: string;
    Status: string;
    DateAsString: string;
}

export interface IApplicationBalanceInfo {
    Label: string;
    Value: string;
    IsBold: boolean;
    FontColor: string;
}
