<header class="content-header">
    <nav class="breadcrumb">
        <a [routerLink]="['/applications', item.Number] | localize" class="bc-back">
            {{'applications_edit_titlePrefix' | translate}}
            "{{item.AdmissionTitle}}", {{item.Number}}
        </a>
    </nav>
</header>

<form #form="ngForm" (submit)="submit()">
    <div class="form-submit form-submit-top">
        <button type="button" (click)="submit()" mat-raised-button color="primary" [disabled]="!formIsValid">
            {{'applications_savePerson' | translate}}
        </button>
    </div>

    <section class="block">
        <header class="block-header">
            <h1 [translate]="'applications_personData'"></h1>
        </header>

        <div class="block-body">
            <div *ngIf="personDataInfo" class="alert alert-info" [innerHTML]="personDataInfo | safeHtml"></div>
            <div *ngIf="isUnder18 && under18Message" class="alert alert-warning bg-warning">
                <i class="fa fa-exclamation-triangle text-danger"></i>
                <div [innerHTML]="under18Message | safeHtml" class="under18-text"></div>
            </div>

            <div class="cols">
                <div class="col-2">
                    <div class="photo-container photo-container-edit">
                        <input type="file" #photoInput (change)="editPhoto({ imageChangedEvent: $event })" accept=".jpg,.jpeg" />
                        <img [src]="photoUrl" *ngIf="item.Id && photoUrl" />
                        <img src="/assets/img/noprofile.png" *ngIf="item.Id && !photoUrl" />
                        <div class="photo-edit-cover">
                            <span (click)="photoInput.click()" class="photo-icon">
                                <i class="fa fa-pencil"></i>
                            </span>
                            <span (click)="deletePhoto()" class="photo-icon" *ngIf="item.Id && photoUrl">
                                <i class="fa fa-trash"></i>
                            </span>
                        </div>
                    </div>
                    <button type="button" (click)="takeSnapshot()" mat-raised-button color="primary" class="btn-take-photo" *ngIf="canTakeSnapshot">
                        {{'applications_btnTakePhoto' | translate}}
                    </button>
                </div>

                <div class="col-9">
                    <div class="cols">
                        <div class="col-4">
                            <mat-form-field>
                                <input matInput [(ngModel)]="item.Name" name="name"
                                       [placeholder]="'applications_lblName' | translate" required>
                            </mat-form-field>
                        </div>

                        <div class="col-4">
                            <mat-form-field>
                                <input matInput [(ngModel)]="item.Surname" name="surname"
                                       [placeholder]="'applications_lblSurname' | translate" required>
                            </mat-form-field>
                        </div>

                        <div class="col-4" *ngIf="!isHomines">
                            <mat-form-field>
                                <mat-select [placeholder]="'applications_lblGender' | translate" [required]="true"
                                            [(ngModel)]="item.GenderId" name="gender">
                                    <mat-option *ngFor="let g of genders" [value]="g.Id">{{g.Value}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="cols" *ngIf="!isHomines">
                        <div class="col-4">
                            <mat-form-field>
                                <input matInput [matDatepicker]="pickerBirthdate" [(ngModel)]="item.Birthdate" name="birthdate" #birthdateInput
                                       [placeholder]="'applications_lblBirthdate' | translate" [min]="env.minDate" [max]="maxBirthDate" required
                                       [title]="'dateFormatHint' | translate" [readonly]="item.IsForeign" (click)="item.IsForeign && pickerBirthdate.open()">
                                <mat-datepicker-toggle matSuffix [for]="pickerBirthdate"></mat-datepicker-toggle>
                                <mat-datepicker #pickerBirthdate></mat-datepicker>
                                <mat-error *ngIf="birthdateInput.invalid">{{'valueInvalid' | translate}}</mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-4" *ngIf="!personCodeHidden">
                            <mat-form-field>
                                <input *ngIf="!IsForeign" matInput [(ngModel)]="item.PersonCode" name="personCode"
                                       [placeholder]="'applications_lblPersonCode' | translate" maxlength="12" required [readonly]="personCodeReadOnly">
                                <input *ngIf="IsForeign" matInput [(ngModel)]="item.PersonCode" name="personCode"
                                       [placeholder]="'applications_lblPersonCode' | translate">
                            </mat-form-field>
                        </div>

                        <div class="col-4">
                            <mat-form-field>
                                <input matInput [(ngModel)]="item.DocBirthPlace" name="docBirthPlace" maxlength="250"
                                       [placeholder]="'applications_lblBirthPlace' | translate" required>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="cols">
                        <div class="col-4">
                            <mat-form-field>
                                <input matInput [(ngModel)]="item.Phone" name="phone"
                                       [placeholder]="'applications_lblPhone' | translate" required>
                            </mat-form-field>
                        </div>

                        <div class="col-4">
                            <mat-form-field>
                                <input matInput [(ngModel)]="item.Email" name="email" type="email" email #email
                                       [placeholder]="'applications_lblEmail' | translate" required>
                                <mat-error *ngIf="!email.valid">{{'emailerror' | translate}}</mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-4" *ngIf="isResidentAdmission">
                            <mat-form-field>
                                <input matInput [(ngModel)]="item.MedicalPersonId" name="medicalPersonId" maxlength="250"
                                       [placeholder]="'applications_lblMedicalPersonId' | translate">
                            </mat-form-field>
                        </div>
                    </div>

                    <p>
                        <mat-checkbox [(ngModel)]="item.SubmittedByRepresentative" name="SubmittedByRepresentative" (click)="confirmDataTransfer()">
                            <span style="white-space: normal;">{{'applications_personSubmittedByRepresentative' | translate}}</span>
                        </mat-checkbox>
                    </p>

                    <div class="cols" *ngIf="item.SubmittedByRepresentative">
                        <div class="col-12">
                            {{'applications_lblpersonSubmitterData' | translate}}
                        </div>
                    </div>

                    <div class="cols" *ngIf="item.SubmittedByRepresentative">
                        <div class="col-4">
                            <mat-form-field>
                                <input matInput [(ngModel)]="item.RepresentativeName" name="representativeName"
                                       [placeholder]="'applications_personRepresentativeName' | translate" readonly>
                            </mat-form-field>
                        </div>

                        <div class="col-4">
                            <mat-form-field>
                                <input matInput [(ngModel)]="item.RepresentativeSurname" name="representativeSurname"
                                       [placeholder]="'applications_personRepresentativeSurname' | translate" readonly>
                            </mat-form-field>
                        </div>

                        <div class="col-4">
                            <mat-form-field>
                                <input matInput [(ngModel)]="item.RepresentativePersonCode" name="representativePersonCode"
                                       [placeholder]="'applications_personRepresentativePersonCode' | translate" maxlength="12" readonly>
                            </mat-form-field>
                        </div>
                    </div>

                    <p>
                        <mat-checkbox [(ngModel)]="item.HasPreviousPersonData" name="HasPreviousPersonData">
                            <span style="white-space: normal;">{{'applications_fillPreviousPersonData' | translate}}</span>
                        </mat-checkbox>
                    </p>

                    <div class="cols" *ngIf="item.HasPreviousPersonData">
                        <div class="col-4">
                            <mat-form-field>
                                <input matInput [(ngModel)]="item.PreviousName" name="PreviousName" required
                                       [placeholder]="'applications_personPreviousName' | translate">
                            </mat-form-field>
                        </div>

                        <div class="col-4">
                            <mat-form-field>
                                <input matInput [(ngModel)]="item.PreviousSurname" name="PreviousSurname" required
                                       [placeholder]="'applications_personPreviousSurname' | translate">
                            </mat-form-field>
                        </div>

                        <div class="col-4">
                            <mat-form-field>
                                <input matInput [(ngModel)]="item.PreviousPersonCode" name="PreviousPersonCode" required
                                       [placeholder]="'applications_personPreviousPersonCode' | translate" maxlength="12">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="block" *ngIf="!isHomines">
        <header class="block-header">
            <h3>
                {{'applications_personDocument' | translate}}
                <a [routerLink]="['/applications', item.Number, item.IsForeign ? 'personDocumentsHistory' : 'history'] | localize"
                   *ngIf="isPowerUser">
                    <i class="fa fa-history" aria-hidden="true"></i>
                </a>
            </h3>
        </header>

        <div class="block-body">
            <div class="input-row" *ngFor="let n of personDocuments; let i = index">
                <div class="cols">
                    <div class="col-5" *ngIf="item.IsForeign">
                        <app-autocomplete [value]="n.CitizenshipId"
                                          [text]="n.Citizenship"
                                          [options]="filteredCitizenships"
                                          [display]="classifierDisplay"
                                          [placeholder]="'applications_lblCitizenship' | translate"
                                          [required]="true"
                                          (selected)="citizenshipSelected($event, n)"
                                          (textChange)="filterCitizenships($event, n)"></app-autocomplete>
                    </div>
                    <div class="col-2" *ngIf="!item.IsForeign">
                        <mat-radio-group [(ngModel)]="n.Type" name="docType-{{i}}">
                            <mat-radio-button [value]="'Passport'" class="mat-align-bottom">{{'applications_docType_Passport' | translate}}</mat-radio-button>
                            <mat-radio-button [value]="'eID'" class="mat-align-bottom">{{'applications_docType_eID' | translate}}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="col-2">
                        <mat-form-field>
                            <input matInput [ngModel]="n.Number" (ngModelChange)="setPersonDocumentNumber($event, n)" name="docNumber-{{i}}" maxlength="128"
                                   [placeholder]="'applications_lblDocNumber' | translate" required>
                        </mat-form-field>
                    </div>
                    <div class="col-2">
                        <mat-form-field>
                            <input matInput [matDatepicker]="pickerDocDate" [(ngModel)]="n.Date" name="docDate-{{getUniqueKey(n)}}"
                                   [placeholder]="'applications_lblDocDate' | translate" [min]="env.minDate" [max]="today" required [title]="'dateFormatHint' | translate">
                            <mat-datepicker-toggle matSuffix [for]="pickerDocDate"></mat-datepicker-toggle>
                            <mat-datepicker #pickerDocDate></mat-datepicker>
                            <mat-error *ngIf="form.controls['docDate-' + getUniqueKey(n)] && form.controls['docDate-' + getUniqueKey(n)].invalid">{{'valueInvalid' | translate}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-2">
                        <mat-form-field>
                            <input matInput [matDatepicker]="pickerDocExpiry" [(ngModel)]="n.Expiry" name="docExpiry-{{getUniqueKey(n)}}"
                                   [placeholder]="'applications_lblDocExpiry' | translate" [min]="today" [max]="env.maxDate" [title]="'dateFormatHint' | translate" required>
                            <mat-datepicker-toggle matSuffix [for]="pickerDocExpiry"></mat-datepicker-toggle>
                            <mat-datepicker #pickerDocExpiry></mat-datepicker>
                            <mat-error *ngIf="form.controls['docExpiry-' + getUniqueKey(n)] && form.controls['docExpiry-' + getUniqueKey(n)].invalid">{{'valueInvalid' | translate}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-{{item.IsForeign ? 5 : 3}}">
                        <app-autocomplete *ngIf="!item.IsForeign"
                                          [value]="n.IssuerId"
                                          [text]="n.Issuer"
                                          [options]="filteredDocIssuers"
                                          [display]="classifierDisplay"
                                          [placeholder]="'applications_lblDocIssuer' | translate"
                                          [required]="true"
                                          [maxlength]="250"
                                          (selected)="docIssuerSelected($event, n)"
                                          (textChange)="filterDocIssuers($event, n)"></app-autocomplete>
                        <mat-form-field *ngIf="item.IsForeign">
                            <input matInput [(ngModel)]="n.Issuer" name="docIssuer-{{i}}" [placeholder]="'applications_lblDocIssuer' | translate" required>
                        </mat-form-field>
                    </div>
                    <div class="col-4" *ngIf="item.IsForeign">
                        <div class="mat-align-bottom">
                            <mat-checkbox [ngModel]="n.IsMain" (ngModelChange)="setPersonDocumentIsMain($event, n)" name="docIsMain-{{i}}"
                                          [value]="true" class="mat-align-top">
                                {{'applications_lblDocIsMain' | translate}}
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="col-1" *ngIf="item.IsForeign">
                        <button type="button" class="input-row-btn btn-icon" (click)="removePersonDocument(n)" *ngIf="personDocuments.length > 1">
                            <i class="fa fa-minus"></i>
                        </button>
                    </div>
                </div>
            </div>

            <div class="rows-action" *ngIf="item.IsForeign">
                <button mat-raised-button (click)="addPersonDocument()" type="button">
                    <i class="fa fa-plus"></i>
                    {{'add' | translate}}
                </button>
            </div>

            <ng-container *ngIf="item.IsForeign">
                <hr />
                <div class="help-block">
                    {{'applications_otherDocsHint' | translate}}
                </div>
                <mat-form-field>
                    <textarea [(ngModel)]="aux.OtherDocs" matInput matTextareaAutosize name="otherDocs"
                              [placeholder]="'applications_lblOtherDocs' | translate"></textarea>
                </mat-form-field>
            </ng-container>

            <div class="cols">
                <div class="col-6">
                    <app-application-aux-files *ngIf="isLoaded"
                                               [label]="'addApplicationFileTypePersonDocument'"
                                               [files]="aux.personDocuments"
                                               [auxType]="'PersonDocument'"
                                               [maxUploadSize]="maxFileUploadSize"
                                               [minRows]="item.IsForeign ? 1 : personDocuments[0].Type === 'eID' ? 2 : 1"
                                               [allowedExtensions]="fileUploadExtensions"></app-application-aux-files>
                </div>
                <ng-container *ngIf="validatePersonDocuments() as documentErrors">
                    <div class="col-12" *ngIf="documentErrors.length">
                        <div class="alert alert-danger" [innerHTML]="documentErrors.join('<br>') | safeHtml"></div>
                    </div>
                </ng-container>
            </div>
        </div>
    </section>

    <app-address *ngIf="!isHomines" [item]="item" [prefix]="'Addr'" [lblTitle]="'applications_declaredAddress'">
    </app-address>
    <app-address *ngIf="!isHomines && !item.IsForeign" [item]="item" [prefix]="'Cont'" [lblTitle]="'applications_contactAddress'" [sameAsPrefix]="'Addr'"
        [sameAsLabel]="'applications_contactAddressIsSameAsDeclaredAddress'"></app-address>

    <ng-container *ngIf="item.IsForeign">
        <section class="block">
            <header class="block-header">
                <h3 [translate]="'applications_languageSkills'"></h3>
            </header>

            <div class="block-body">
                <div class="cols input-row" *ngFor="let n of aux.Languages; let i = index">
                    <div class="col-3">
                        <mat-form-field>
                            <input matInput [(ngModel)]="n.Language" [placeholder]="'applications_lblLang' | translate" name="lang_{{i}}" required maxlength="50"
                                   [readonly]="i === 0" />
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <mat-form-field>
                            <mat-select [(ngModel)]="n.Level" [placeholder]="'applications_lblLangLevel' | translate" name="langLevel_{{i}}" required>
                                <mat-option *ngFor="let x of languageLevels" [value]="x">
                                    {{'applications_langLevel_' + x | translate}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-3" *ngIf="showLanguageCertification(n)">
                        <mat-form-field>
                            <mat-select [(ngModel)]="n.Cert" [placeholder]="'applications_lblLangCert' | translate" name="langCert_{{i}}">
                                <mat-option *ngFor="let x of languageCertificates" [value]="x.Value">{{x.Value}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-2" *ngIf="n.Cert">
                        <mat-form-field>
                            <input matInput [(ngModel)]="n.Score" name="langScore_{{i}}" [placeholder]="'applications_lblLangScore' | translate">
                        </mat-form-field>
                    </div>
                    <div class="col-1">
                        <button type="button" class="btn-icon input-row-btn" (click)="removeLanguageSkill(n)"
                                *ngIf="i > 0"><i class="fa fa-minus"></i></button>
                    </div>
                </div>
                <div>
                    <button mat-raised-button (click)="addLanguageSkill()" type="button">
                        <i class="fa fa-plus"></i>
                        {{'add' | translate}}
                    </button>
                </div>
            </div>
        </section>
    </ng-container>

    <section class="block" *ngIf="!isHomines">
        <header class="block-header">
            <h3>
                {{'applications_eduInstitutions' | translate}}
                <a [routerLink]="['/applications', item.Number, 'educationsHistory'] | localize" *ngIf="isPowerUser">
                    <i class="fa fa-history" aria-hidden="true"></i>
                </a>
            </h3>
        </header>

        <div class="block-body">
            <div class="input-row" *ngFor="let n of educations; let i = index">
                <div class="cols">
                    <div class="col-2" *ngIf="!item.IsForeign">
                        <mat-form-field *ngIf="isAdmin || !n.ExtSource else eduAcqInRO">
                            <mat-select [(ngModel)]="n.EducationAcquiredInId" #eduAcqIn [placeholder]="'applications_lblEduAcqiredIn' | translate" name="eduAcqInstitution_{{getUniqueKey(n)}}"
                                        [required]="true" (selectionChange)="educationAcquiredInChanged(eduAcqIn.value, n.Id)">
                                <mat-option *ngFor="let opt of educationAcquiredInPlaces" [value]="opt.Id">{{opt.Value}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <ng-template #eduAcqInRO>
                            <app-field-display [value]="classifierDisplayById(n.EducationAcquiredInId, educationAcquiredInPlaces)" [label]="'applications_lblEduAcqiredIn' | translate"></app-field-display>
                        </ng-template>
                    </div>
                    <div class="col-6">
                        <app-autocomplete *ngIf="isAdmin || !n.ExtSource else institutionRO"
                                          [value]="n.InstitutionId"
                                          [text]="n.Institution"
                                          [options]="educationInstitutions"
                                          [display]="classifierDisplay"
                                          [placeholder]="'applications_lblEduInstitution' | translate"
                                          [required]="true"
                                          [disabled]="!isAdmin && !!n.ExtSource"
                                          (selected)="educationInstitutionSelected($event, n)"
                                          (textChange)="filterEducationInstitutions($event, n)"
                                          [debounce]="500"></app-autocomplete>
                        <ng-template #institutionRO>
                            <app-field-display [value]="n.Institution" [label]="'applications_lblEduInstitution' | translate"></app-field-display>
                        </ng-template>
                    </div>
                    <div class="col-2" [title]="'applications_lblEduYearTo' | translate">
                        <mat-form-field *ngIf="isAdmin || !n.ExtSource else eduYearToRO">
                            <input matInput [(ngModel)]="n.YearTo" name="eduYearTo_{{getUniqueKey(n)}}" [placeholder]="'applications_lblEduYearTo' | translate"
                                   [min]="minEducationYear" [max]="maxEducationYear" type="text" maxlength="4"
                                   yearvalidator [minYear]="minEducationYear" [maxYear]="maxEducationYear">
                            <mat-error *ngIf="form.controls['eduYearTo_' + getUniqueKey(n)] && form.controls['eduYearTo_' + getUniqueKey(n)].invalid">
                                {{'valueInvalid' | translate}}
                            </mat-error>
                        </mat-form-field>
                        <ng-template #eduYearToRO>
                            <app-field-display [value]="n.YearTo" [label]="'applications_lblEduYearTo' | translate"></app-field-display>
                        </ng-template>
                    </div>
                    <div class="col-2">
                        <button type="button" mat-raised-button class="input-row-btn" (click)="removeEducation(n)" *ngIf="(isAdmin || !n.ExtSource) && educations.length > 1">
                            <i class="fa fa-minus"></i>
                            {{'delete' | translate}}
                        </button>
                        <span *ngIf="isEditor && !!n.ExtSource" style="margin-left: 2%;" class="badge" [ngClass]="'ext-source-' + n.ExtSource.toLowerCase()">
                                {{'applications_edu_ext_source' | translate | formatString:(n.ExtSource)}}
                        </span>
                    </div>
                </div>
                <div class="cols">
                    <div class="col-6">
                        <mat-form-field *ngIf="isAdmin || !n.ExtSource || n.ExtSource === 'VIIS' else eduLevelRO">
                            <mat-select [(ngModel)]="n.LevelId" [placeholder]="'applications_lblEduLevel' | translate" name="eduLevel_{{getUniqueKey(n)}}">
                                <mat-option *ngFor="let opt of applicableEducationLevels(n.ExtSource)" [value]="opt.Id">{{opt.Value}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <ng-template #eduLevelRO>
                            <app-field-display [value]="classifierDisplayById(n.LevelId, educationLevels)" [label]="'applications_lblEduLevel' | translate"></app-field-display>
                        </ng-template>
                    </div>
                    <div class="col-4" *ngIf="!IsForeign">
                        <mat-form-field *ngIf="isAdmin || !n.ExtSource else eduDiplomaNumberRO">
                            <input matInput [ngModel]="n.DiplomaNumber" (ngModelChange)="diplomaNumberChange($event, n)" name="eduDiplomaNumber_{{getUniqueKey(n)}}"
                                [placeholder]="'applications_lblEduDiplomaNumber' | translate">
                        </mat-form-field>
                        <ng-template #eduDiplomaNumberRO>
                            <app-field-display [value]="n.DiplomaNumber" [label]="'applications_lblEduDiplomaNumber' | translate"></app-field-display>
                        </ng-template>
                    </div>
                </div>
                <div class="col-12" *ngIf="validateEducation(n).length">
                    <div class="alert alert-danger" [innerHTML]="validateEducation(n).join('<br>') | safeHtml"></div>
                </div>
                <div class="col-12 cols" *ngIf="isEditor">
                    <div class="col-4">
                        <mat-checkbox [checked]="n.IsSelected" (change)="eduIsSelectedChange($event, n)" name="eduIsSeleclted_{{i}}"
                                        [value]="true" class="mat-align-bottom">
                            <span style="white-space: normal;">{{'applications_lblEduIsSelected' | translate}}</span>
                        </mat-checkbox>
                    </div>
                    <div class="col-4">
                        <mat-checkbox [(ngModel)]="n.SendFilesToDvs" name="eduSendFilesToDvs_{{i}}" class="mat-align-bottom">
                            <span style="white-space: normal;">{{'applications_lblEduSendFilesToDvs' | translate}}</span>
                        </mat-checkbox>
                    </div>
                </div>
                <div class="cols edu-docs">
                    <ng-container *ngIf="aux.education[n.Id]">
                        <div class="col-6">
                            <h5>{{'applicationFileTypeEduAttestation' | translate}}</h5>
                            <app-application-aux-files *ngIf="isLoaded"
                                                    [label]="'addApplicationFileTypeEduAttestation'"
                                                    [files]="aux.education[n.Id].attestations"
                                                    [auxType]="'EduAttestation_' + n.Id"
                                                    [minRows]="this.IsForeign ? 0 : 1"
                                                    [maxUploadSize]="maxFileUploadSize"
                                                    [allowedExtensions]="fileUploadExtensions"></app-application-aux-files>
                        </div>
                        <div class="col-6">
                            <h5>{{'applicationFileTypeEduGrades' | translate}}</h5>
                            <app-application-aux-files *ngIf="isLoaded"
                                                    [label]="'addApplicationFileTypeEduGrades'"
                                                    [files]="aux.education[n.Id].grades"
                                                    [auxType]="'EduGrades_' + n.Id"
                                                    [minRows]="this.IsForeign ? 0 : 1"
                                                    [maxUploadSize]="maxFileUploadSize"
                                                    [allowedExtensions]="fileUploadExtensions"></app-application-aux-files>
                        </div>
                        <div class="col-6" *ngIf="showAuxAicOpinion(n)">
                            <h5>{{'applicationFileTypeEduAicOpinion' | translate}}</h5>
                            <app-application-aux-files *ngIf="isLoaded"
                                                    [label]="'addApplicationFileTypeEduAicOpinion'"
                                                    [files]="aux.education[n.Id].aicOpinions"
                                                    [auxType]="'EduAicOpinion_' + n.Id"
                                                    [maxUploadSize]="maxFileUploadSize"
                                                    [allowedExtensions]="fileUploadExtensions"></app-application-aux-files>
                        </div>
                        <div class="col-6">
                            <h5>{{'applicationFileTypeEduOther' | translate}}</h5>
                            <app-application-aux-files *ngIf="isLoaded"
                                                    [label]="'addApplicationFileTypeEduOther'"
                                                    [files]="aux.education[n.Id].other"
                                                    [auxType]="'EduOther_' + n.Id"
                                                    [maxUploadSize]="maxFileUploadSize"
                                                    [allowedExtensions]="fileUploadExtensions"></app-application-aux-files>
                        </div>
                        </ng-container>
                    <div class="col-12" *ngIf="validateEducationDocuments(n).length">
                        <div class="alert alert-danger" [innerHTML]="validateEducationDocuments(n).join('<br>') | safeHtml"></div>
                    </div>
                    <div class="col-12">
                        <p *ngIf="aux.education[n.Id] && aux.education[n.Id].personDataDiffer && aux.education[n.Id].personDataDiffer.value">
                            <mat-checkbox [(ngModel)]="aux.education[n.Id].personDataDiffer.value" name="eduPersonDataDiffer_{{getUniqueKey(n)}}" [value]="true">
                                {{'applications_lblEduPersonDataDiffer' | translate}}
                            </mat-checkbox>
                        </p>
                    </div>
                    <div class="col-8">
                        <mat-form-field *ngIf="aux.education[n.Id] && aux.education[n.Id].personDataDiffer && aux.education[n.Id].personDataDiffer.value">
                            <textarea matInput [(ngModel)]="aux.education[n.Id].personDataDiffer.text" name="eduPersonDataDifferText_{{getUniqueKey(n)}}"
                                      [placeholder]="'applications_lblEduPersonDataDiffer' | translate"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="rows-action">
                <button mat-raised-button (click)="addEducation()" type="button">
                    <i class="fa fa-plus"></i>
                    {{'add_institution' | translate}}
                </button>
            </div>
        </div>
    </section>
    <section class="block" *ngIf="isHomines">
        <header class="block-header">
            <h3>{{'applications_myInstitution' | translate}}</h3>
        </header>
        <div class="cols">
            <div class="col-4">
                    <app-autocomplete [value]="myInstitution"
                                      [text]="classifierDisplay(myInstitution)"
                                      [display]="classifierDisplay"
                                      [options]="educationInstitutions"
                                      [placeholder]="'applications_myInstitutionName' | translate"
                                      [required]="true"
                                      (selected)="educationMyInstitutionSelected($event)"
                                      (textChange)="filterMyEducationInstitutions($event)"></app-autocomplete>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <mat-select [(ngModel)]="item.MyInstitutionClassId" [placeholder]="'applications_myInstitutionClass' | translate" name="myInstitutionClass" [required]="true">
                        <mat-option *ngFor="let opt of educationLevels" [value]="opt.Id">{{classifierDisplay(opt)}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </section>
    <section class="block" *ngIf="isHomines">
        <header class="block-header">
            <h3>{{'applications_responsibleForFirst' | translate}}</h3>
        </header>
        <div class="cols">
            <div class="col-4">
                <mat-form-field>
                    <input matInput [(ngModel)]="item.ResponsibleName" name="responsibleName"
                           [placeholder]="'applications_lblName' | translate" required>
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <input matInput [(ngModel)]="item.ResponsibleLastName" name="responsibleLastName"
                           [placeholder]="'applications_lblSurname' | translate" required>
                </mat-form-field>
            </div>
        </div>
        <div class="cols">
            <div class="col-4">
                <mat-form-field>
                    <input matInput [(ngModel)]="item.ResponsiblePhone" name="responsiblePhone"
                           [placeholder]="'applications_lblPhone' | translate" required>
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <input matInput [(ngModel)]="item.ResponsibleMail" name="responsibleMail"
                           [placeholder]="'applications_lblEmail' | translate" required>
                </mat-form-field>
            </div>
        </div>
    </section>

    <div class="form-submit">
        <button type="button" (click)="submit()" mat-raised-button color="primary" [disabled]="!formIsValid">
            {{'applications_savePerson' | translate}}
        </button>
    </div>
</form>

<app-application-notes *ngIf="item"
                       [applicationId]="item.Id"
                       [position]="'PersonData'"
                       [single]="true"
                       [canEdit]="isEditor">
</app-application-notes>
