<app-program-content-wrapper [program]="program" [breadcrumbTitle]="admissionTitle"
                             [formTitle]="'programInterviews_title' | translate">
    <ng-template let-program="program">

        <form class="filter-form-sm">
            <div style="display: flex; justify-content: space-between; align-items: center;">
                <div style="flex-basis: 150px; margin-right: 20px;">
                    <mat-form-field>
                        <mat-select [placeholder]="'programInterviews_lblDate' | translate" [(ngModel)]="filter.Date"
                                    name="filterDate" (selectionChange)="applyFilter()">
                            <mat-option [value]="null"></mat-option>
                            <mat-option [value]="'-'">{{'optionNone' | translate}}</mat-option>
                            <mat-option *ngFor="let d of scheduledDates" [value]="d">{{ d | appDate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div style="flex-basis: 250px;">
                    <mat-form-field>
                        <mat-select [placeholder]="'programInterviews_lblRoom' | translate" [(ngModel)]="filter.RoomId"
                                    name="filterRoom" (selectionChange)="applyFilter()">
                            <mat-option [value]="null"></mat-option>
                            <mat-option [value]="'-'">{{'optionNone' | translate}}</mat-option>
                            <mat-option *ngFor="let sr of scheduledRooms" [value]="sr.Id">{{ sr.Text }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div style="flex-grow: 1;"></div>
                <button type="button" class="btn-inline" mat-raised-button color="primary" (click)="refreshInterviews()">
                    <i class="fa fa-refresh"></i> {{'refresh' | translate }}
                </button>
            </div>
        </form>

        <div class="grid">
            <table class="table table-sticky tight">
                <thead>
                    <tr>
                        <ng-container *ngFor="let n of columns; let i = index">
                            <th [class.sticky]="n.sticky && (i == 0 || i == columns.length - 1)"
                                [class.check-cell]="i == 0"
                                [class.sorts]="n.sort"
                                [title]="n.title ? (n.title | translate) : ''"
                                (click)="sort(n)">
                                <div *ngIf="i == 0; else standardTh" class="app-number-cell no-ripple">
                                    <mat-checkbox (change)="selectRows($event)" value="true" [checked]="allRowsSelected()"
                                                  (click)="$event.stopPropagation()" *ngIf="i == 0"></mat-checkbox>
                                    <span>
                                        {{n.title ? (n.title | translate) : ''}}
                                        <span *ngIf="n.isSorted" class="sorted-{{n.isSorted}}"></span>
                                    </span>
                                </div>
                                <ng-template #standardTh>
                                    {{n.title ? (n.title | translate) : ''}}
                                    <span *ngIf="n.isSorted" class="sorted-{{n.isSorted}}"></span>
                                </ng-template>
                            </th>
                        </ng-container>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let n of rows">
                        <td class="sticky">
                            <div class="app-number-cell check-cell">
                                <mat-checkbox [value]="n.Id" (change)="selectRow(n)" [checked]="rowSelected(n)"></mat-checkbox>
                                <a [routerLink]="['/applications', n.ApplicationNumber] | localize">
                                    {{n.ApplicationNumber}}
                                    <i class="fa fa-calendar" *ngIf="n.IsLateSubmission"></i>
                                </a>
                            </div>
                        </td>
                        <td>{{n.Name}}</td>
                        <td>{{n.Surname}}</td>
                        <td>
                            <i class="fa fa-info-circle icon-btn" (click)="openContacts(n)"></i>
                        </td>
                        <td>{{n.PersonCode}}</td>
                        <td>{{n.RoomName}}</td>
                        <td>
                            <a href="{{n.InterviewOnlineUrl}}" target="_blank" [title]="n.InterviewOnlineUrl" *ngIf="n.InterviewOnlineUrl">
                                <i class="fa fa-external-link"></i>
                            </a>
                        </td>
                        <td>{{n.InterviewTime | appDate}}</td>
                        <td>{{n.InterviewTime | appTime}}</td>
                        <td>
                            <a *ngIf="n.canReschedule && (n.InterviewTime && n.InterviewTime > today)" [routerLink]="[]" (click)="pickTime(n, timepicker)">
                                <i class="fa fa-pencil"></i>
                            </a>
                        </td>
                        <td>
                            <a *ngIf="n.ScheduleConflicts.length" [routerLink]="[]" (click)="openConflicts(n.ScheduleConflicts)">
                                <i class="fa fa-exclamation-triangle" style="color:orange;"></i>
                            </a>
                        </td>
                        <td>
                            <i class="fa fa-check" *ngIf="n.isAbsent"></i>
                        </td>
                        <td *ngFor="let iv of interviewers">
                            <a [routerLink]="['/interviews', n.ApplicationExamId, iv.Id] | localize">
                                {{interviewStatusText(n.InterviewResults[iv.Id])}}
                            </a>
                        </td>
                        <td>{{n.AverageGrade}}</td>
                        <td>
                            <i *ngIf="n.InvitationMessageCode" class="fa fa-external-link icon-btn" (click)="openNotification(n.ApplicationId, n.ApplicationExamId)"></i>
                            <small>{{n.InvitationMessageCode}}</small>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="table-total">
            <strong>{{rows.length || 0}}</strong> {{'gridTotal' | translate}}
        </div>

        <ngx-material-timepicker #timepicker [ESC]="true" [enableKeyboardInput]="true" [format]="24"
                                 (timeSet)="timePicked($event)"></ngx-material-timepicker>
    </ng-template>
</app-program-content-wrapper>

<ng-container *ngIf="isPlanner">
    <form #planningForm="ngForm" class="block form-in form-inline">
        <header class="block-header">
            <h3 [translate]="'programInterviews_planningActions'"></h3>
        </header>

        <div class="block-body">
            <mat-form-field>
                <mat-select [placeholder]="'programInterviews_lblPlanningAction' | translate"
                            [(ngModel)]="planningOpts.action" name="planningAction" required>
                    <mat-option *ngFor="let a of planningActions" [value]="a">
                        {{'programInterviews_planningAction_' + a | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-select [placeholder]="'programInterviews_lblPlanningTarget' | translate"
                            [(ngModel)]="planningOpts.target" name="planningTarget" required>
                    <mat-option *ngFor="let t of planningTargets" [value]="t">
                        {{'programInterviews_planningTarget_' + t | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="planningOpts.action === 'Set'">
                <input matInput [(ngModel)]="planningOpts.url" name="planningUrl" [placeholder]="'programInterviews_lblUrl' | translate" required />
            </mat-form-field>
            <mat-form-field *ngIf="planningOpts.action === 'Set'">
                <input matInput [matDatepicker]="pickerPlanningDate" [(ngModel)]="planningOpts.date"
                       name="planningDate" [placeholder]="'programInterviews_lblDate' | translate" required
                       [min]="today" [max]="maxDate" [title]="'dateFormatHint' | translate">
                <mat-datepicker-toggle matSuffix [for]="pickerPlanningDate"></mat-datepicker-toggle>
                <mat-datepicker #pickerPlanningDate></mat-datepicker>
            </mat-form-field>
            <mat-form-field *ngIf="planningOpts.action === 'Set'">
                <mat-select [placeholder]="'programInterviews_lblRoom' | translate"
                            [(ngModel)]="planningOpts.roomId" name="planningRoom" required>
                    <mat-option *ngFor="let a of rooms | keyvalue:compareValues" [value]="a.key">
                        {{a.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <button type="button" class="btn-inline" mat-raised-button color="primary" (click)="updateSchedules()"
                    [disabled]="!planningForm.valid || !rows.length || planningOpts.target === PlanningTarget[PlanningTarget.Selected] && !selectedRows.length">
                {{'ok' | translate}}
            </button>
        </div>
    </form>
    <div class="cols">
        <div class="col-7">
            <form #notificationForm="ngForm" class="block form-in form-inline">
                <header class="block-header">
                    <h3 [translate]="'programInterviews_lblCreateOrDeleteEmails'" class="form-title"></h3>
                </header>

                <div class="block-body">
                    <mat-form-field style="min-width: 250px">
                        <mat-select matInput [placeholder]="'programInterviews_lblNotificationTarget' | translate"
                                    name="notificationTarget" required [(ngModel)]="notificationOpts.target">
                            <mat-option *ngFor="let t of notificationTargets" [value]="t">
                                {{'programInterviews_notificationTarget_' + t | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field style="min-width: 250px">
                        <input matInput [placeholder]="'programInterviews_lblNotificationCode' | translate" [(ngModel)]="notificationOpts.code"
                               name="notificationCode" required #notifCode
                               [matAutocomplete]="notifCodeAuto.bind(notifCode)" />
                        <app-message-autocomplete #notifCodeAuto></app-message-autocomplete>
                    </mat-form-field>

                    <button type="submit" class="btn-inline" mat-raised-button color="primary"
                            (click)="createOrDeleteNotification()" [disabled]="!notificationForm.valid">
                        {{'programInterviews_lblSendEmail' | translate}}
                    </button>
                </div>
            </form>
        </div>
    </div>

</ng-container>
