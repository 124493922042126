<form #form="ngForm" (ngSubmit)="submit()">
    <h2 mat-dialog-title [translate]="'applications_workplaceSelectTitle'"></h2>

    <mat-dialog-content>
        <div class="alert alert-info" *ngIf="!isReadOnly">
            {{'applicationPrograms_workplaceAlert' | translate}}
        </div>

        <div class="row" *ngFor="let n of selectedWorkplaces; let i = index">
                <div class="priority">
                    {{n.Priority}}.
                </div>
                <div class="name">
                    <app-field-display [value]="n.Name" [label]="i === 0 ? ('programs_lblWorkplace' | translate) : null"></app-field-display>
                </div>

                <div class="buttons" *ngIf="!isReadOnly">
                    <button type="button" mat-mini-fab color="primary" (click)="moveUp(n)" [disabled]="i == 0"
                            [title]="'applicationPrograms_moveUp' | translate">
                        <i class="fa fa-caret-up"></i>
                    </button>

                    <button type="button" mat-mini-fab color="primary" (click)="moveDown(n)" [disabled]="i == selectedWorkplaces.length - 1"
                            [title]="'applicationPrograms_moveDown' | translate">
                        <i class="fa fa-caret-down"></i>
                    </button>
                </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-raised-button type="submit" color="primary" [disabled]="!form.valid" *ngIf="!isReadOnly">{{'save' | translate}}</button>
        <button mat-button mat-dialog-close>{{(isReadOnly ? 'close' : 'cancel') | translate}}</button>
    </mat-dialog-actions>
</form>
