<app-program-content-wrapper [program]="program" [breadcrumbTitle]="admissionTitle" [formTitle]="'programResults_title' | translate">
    <ng-template let-program="program">
        <form class="filter-form-sm">
            <div class="cols">
                <div class="col-2">
                    <mat-form-field>
                        <mat-select [placeholder]="'programResults_lblResult' | translate"
                                    [(ngModel)]="filterResult" name="filterResult" (selectionChange)="filter()">
                            <mat-option [value]="''">{{'optionNone' | translate}}</mat-option>
                            <mat-option *ngFor="let o of filterResultOptions" [value]="o">{{'applicationResultType_' + o | translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-3">
                    <mat-form-field>
                        <input #search
                               matInput
                               [(ngModel)]="filterText"
                               (keyup)="filter()"
                               name="filterText"
                               spellcheck="false"
                               [placeholder]="'lblSearch' | translate">
                        <button mat-button *ngIf="search.value" matSuffix mat-icon-button [title]="'lblClear' | translate"
                                (click)="filterText = ''; filter()">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                </div>

                <div class="col-2">
                    <mat-form-field>
                        <mat-select [placeholder]="'programResults_lblContractStatus' | translate"
                                    [(ngModel)]="filterContractStatus" name="filterContractStatus" (selectionChange)="filter()">
                            <mat-option [value]="''">{{'optionNone' | translate}}</mat-option>
                            <mat-option *ngFor="let o of filterContractStatusOptions" [value]="o">
                                {{ o | translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-2">
                    <mat-form-field>
                        <mat-select [placeholder]="'programResults_lblEnrollmentStatus' | translate"
                                    [(ngModel)]="filterEnrollmentStatus" name="filterEnrollmentStatus" (selectionChange)="filter()">
                            <mat-option [value]="''">{{'optionNone' | translate}}</mat-option>
                            <mat-option *ngFor="let o of filterEnrollmentStatusOptions" [value]="o">
                                {{o | translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-3">
                    <mat-form-field>
                        <input #searchNotifCode
                               matInput
                               [(ngModel)]="filterNotificationCode"
                               (keyup)="filter()"
                               name="filterNotificationCode"
                               spellcheck="false"
                               [placeholder]="'programResults_lblNotificationCode' | translate">
                        <button mat-button *ngIf="searchNotifCode.value" matSuffix mat-icon-button [title]="'lblClear' | translate"
                                (click)="filterNotificationCode = ''; filter()">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
            </div>
            <div class="cols">
                <div class="col-2">
                    <mat-checkbox [(ngModel)]="filterIsLateSubmission" name="filterIsLateSubmission" (change)="filter()">
                        <span style="white-space: normal;">{{'programResults_lblIsLateSubmission' | translate }}</span>
                    </mat-checkbox>
                </div>
                <div class="col-2" *ngIf="isForeign==false">
                    <mat-checkbox [(ngModel)]="filterIsIncomplete" name="filterIsIncomplete" (change)="filter()">
                        <span style="white-space: normal;">{{'programResults_lblIsIncomplete' | translate }}</span>
                    </mat-checkbox>
                </div>
                <div class="col-2">
                    <mat-checkbox [(ngModel)]="filterApplicationsSubmittedByRepresentative" name="filterApplicationsSubmittedByRepresentative" (change)="filter()">
                        <span style="white-space: normal;">{{'programResults_lblApplicationsForAnotherPerson' | translate }}</span>
                    </mat-checkbox>
                </div>
                <div class="col-2">
                    <mat-checkbox [(ngModel)]="filterHasNotificationCode" name="filterHasNotificationCode" (change)="filter()">
                        <span style="white-space: normal;">{{'programResults_lblNotificationCode' | translate}}</span>
                    </mat-checkbox>
                </div>
                <div class="col-2">
                    <mat-checkbox [(ngModel)]="filterOnlyGraduates" name="filterOnlyGraduates" (change)="filter()">
                        <span style="white-space: normal;">{{'programResults_lblOnlyGraduates' | translate}}</span>
                    </mat-checkbox>
                </div>
            </div>
        </form>

        <div class="grid">
            <table class="table table-sticky tight">
                <thead>
                    <tr>
                        <ng-container *ngFor="let n of columns; let i = index">
                            <th [class.sticky]="n.sticky && (i == 0 || i == columns.length - 1)"
                                [class.check-cell]="i == 0"
                                [class.sorts]="n.sort"
                                [title]="n.title ? (n.title | translate) : ''"
                                (click)="sort(n)">
                                <div *ngIf="i == 0; else standardTh" class="app-number-cell no-ripple">
                                    <mat-checkbox (change)="selectRows($event)" value="true" [checked]="allRowsSelected()"
                                                  (click)="$event.stopPropagation()" *ngIf="i == 0"></mat-checkbox>
                                    <span>
                                        {{n.title ? (n.title | translate) : ''}}
                                        <span *ngIf="n.isSorted" class="sorted-{{n.isSorted}}"></span>
                                    </span>
                                </div>
                                <ng-template #standardTh>
                                    {{n.title ? (n.title | translate) : ''}}
                                    <span *ngIf="n.isSorted" class="sorted-{{n.isSorted}}"></span>
                                </ng-template>
                            </th>
                        </ng-container>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let n of rows">
                        <td class="sticky">
                            <div class="app-number-cell check-cell">
                                <mat-checkbox [value]="n.Id" (change)="selectRow(n)" [checked]="rowSelected(n)"></mat-checkbox>
                                <a [routerLink]="['/applications', n.ApplicationNumber] | localize">
                                    {{n.ApplicationNumber}}
                                    <i class="fa fa-calendar" *ngIf="n.IsLateSubmission"></i>
                                </a>
                            </div>
                        </td>
                        <td>
                            <i *ngIf="value" style="color:red;" class="fa fa-exclamation danger" [title]="'programResults_lblIsIncomplete' | translate"></i>
                        </td>
                        <td>{{n.Name}}</td>
                        <td>{{n.Surname}}</td>
                        <td>
                            <i class="fa fa-info-circle icon-btn" (click)="openContacts(n)"></i>
                        </td>
                        <td>{{n.PersonCode}}</td>
                        <td>{{n.Priority}}</td>
                        <td>
                            <i class="fa fa-check" *ngIf="n.ExamsFinished"></i>
                        </td>
                        <td>{{n.WeightedResult | number:'1.3-3'}}</td>
                        <td>{{'applicationStatus_' + n.ApplicationStatus | translate}}</td>
                        <td *ngIf="program?.IsWorkplaceRequired">
                            <div class="text-ellipsis" style="width: 200px;" [title]="n.SelectedWorkplace" *ngIf="!workplaceEditing[n.Id]">
                                <i class="fa fa-pencil icon-btn" (click)="editWorkplace(n)"></i>
                                {{n.SelectedWorkplace}}
                            </div>
                            <mat-form-field [floatLabel]="'never'" *ngIf="workplaceEditing[n.Id]">
                                <mat-select (selectionChange)="onWorkplaceChange($event, n)" [value]="n.SelectedWorkplaceId">
                                    <mat-option [value]="undefined">-</mat-option>
                                    <mat-option *ngFor="let opt of availableWorkplaces" [value]="opt.Id">{{opt.Name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td>
                            <div *ngIf="!resultEditing[n.Id]">
                                <i *ngIf="n.ApplicationStatus && n.ApplicationStatus!='New'" class="fa fa-pencil icon-btn" (click)="editResult(n)"></i>
                                {{'applicationResultType_' + n.Result | translate}}
                            </div>
                            <mat-form-field [floatLabel]="'never'" *ngIf="resultEditing[n.Id]">
                                <mat-select (selectionChange)="onResultChange($event, n)" [value]="n.Result">
                                    <mat-option disabled [value]="n.Result">{{'applicationResultType_' + n.Result | translate}}</mat-option>
                                    <mat-option *ngFor="let opt of availableResults" [value]="opt">{{'applicationResultType_' + opt | translate}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td>{{n.Place}}</td>
                        <td>{{getContractStatus(n.ContractStatus)}}</td>
                        <td>
                            {{n.EnrollmentStatus | translate}}
                            <i class="fa fa-info-circle icon-btn" (click)="openBalance(n)"></i>
                        </td>
                        <td>
                            <i *ngIf="n.NotificationCode" class="fa fa-external-link icon-btn" (click)="openNotification(n.ApplicationId)"></i>
                            {{n.NotificationCode}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="table-total">
            <strong>{{rows.length || 0}}</strong> {{'gridTotal' | translate}}
        </div>

        <div class="cols">
            <div class="col-6">
                {{'programResults_lblTotalStudentsIn' | translate}}: <em>{{totalStudentsIn || 0}}</em>
            </div>

            <div class="col-6 text-right">
                <button mat-raised-button type="button" color="accent" (click)="arrangeWorkplaces()" [disabled]="!arrangeWorkplacesEnabled"
                        *ngIf="program?.IsWorkplaceRequired">
                    <i class="fa fa-sort-amount-asc"></i>
                    {{'programs_arrangeWorkplaces' | translate}}
                </button>
            </div>
        </div>
    </ng-template>
</app-program-content-wrapper>

<div class="cols">
    <div class="col-7">
        <form #notificationForm="ngForm" class="block form-in form-inline">
            <header class="block-header">
                <h3 [translate]="'programResults_createOrDeleteNotification'" class="form-title"></h3>
            </header>

            <div class="block-body">
                <mat-form-field>
                    <mat-select [placeholder]="'programResults_lblNotificationAction' | translate" [(ngModel)]="notificationOpts.action" name="notificationAction" required>
                        <mat-option *ngFor="let a of notificationActions" [value]="a">
                            {{'programResults_notificationAction_' + a | translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field style="min-width: 250px" *ngIf="notificationOpts.action==='create'">
                    <input matInput [placeholder]="'programResults_lblNotificationCode' | translate" [(ngModel)]="notificationOpts.code"
                        name="notificationCode" required #notifCode
                        [matAutocomplete]="notifCodeAuto.bind(notifCode)" />
                    <app-message-autocomplete #notifCodeAuto></app-message-autocomplete>
                </mat-form-field>

                <mat-form-field style="min-width: 250px" *ngIf="notificationOpts.action==='create'">
                    <input matInput [placeholder]="'programResults_lblNotificationCodeExternal' | translate" [(ngModel)]="notificationOpts.codeExternal"
                        name="notificationCodeExternal" #notifCodeExternal required
                        [matAutocomplete]="notifCodeExternalAuto.bind(notifCodeExternal)" />
                    <app-message-autocomplete #notifCodeExternalAuto></app-message-autocomplete>
                </mat-form-field>

                <mat-form-field *ngIf="!!notificationOpts.action">
                    <mat-select [placeholder]="'programResults_lblNotificationTarget' | translate" [(ngModel)]="notificationOpts.target" name="notificationTarget" required>
                        <mat-option *ngFor="let t of notificationTargets" [value]="t">
                            {{'programResults_notificationTarget_' + t | translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <button type="submit" class="btn-inline" mat-raised-button color="primary" (click)="createOrDeleteNotification()" [disabled]="!notificationForm.valid">
                    {{'ok' | translate}}
                </button>
            </div>
        </form>
    </div>

    <div class="col-5" *ngIf="!isHomines">
        <form #contractForm="ngForm" class="block form-in form-inline">
            <header class="block-header">
                <h3 [translate]="'programResults_actionsWithApplicants'"></h3>
            </header>

            <div class="block-body">
                <mat-form-field>
                    <mat-select [placeholder]="'programResults_lblContractTarget' | translate" [(ngModel)]="contractOpts.target" name="contractTarget" required>
                        <mat-option *ngFor="let t of contractTargets" [value]="t">
                            {{'programResults_contractTarget_' + t | translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-select [placeholder]="'programResults_lblContractAction' | translate" [(ngModel)]="contractOpts.action" name="contractAction" required>
                        <mat-option *ngFor="let a of contractActions" [value]="a">
                            {{'programResults_contractAction_' + a | translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <button type="button" class="btn-inline" mat-raised-button color="primary" (click)="processContracts()" [disabled]="!contractForm.valid">
                    {{'ok' | translate}}
                </button>
            </div>
        </form>
    </div>
</div>
