import { Component, OnInit, Inject, ViewChild, ElementRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Subject, of, zip } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { AppService } from '../services/app.service';
import { ApplicationService } from '../services/application.service';
import { ClassifierService } from '../services/classifier.service';
import { ParameterService } from '../services/parameter.service';
import { MessagesService } from '../messages/messages.service';

import { Application, ApplicationEducation, ApplicationAuxData, ApplicationPersonDocument } from '../models/Application';
import { Classifier } from '../models/Classifier';
import { Territory } from '../models/Territory';
import { Message } from '../models/Message';
import { ParameterValue } from '../models/Parameter';
import { UserType } from '../models/UserInfo';

import { ICanDeactivateGuard } from '../CanDeactivateGuard';
import { Utils } from '../Utils';
import {
    defaultMinApplicantAge,
    defaultMaxFileUploadSize,
    defaultFileUploadExtensions,
    parseValidationErrors,
    AuxDataParser
} from './common';

import { environment } from '../../environments/environment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-application-person-edit',
    templateUrl: './person-edit.component.html',
    styleUrls: ['./person.component.css']
})
export class PersonEditComponent implements OnInit, ICanDeactivateGuard {
    constructor(
        public app: AppService,
        private service: ApplicationService,
        private classifierService: ClassifierService,
        private parameterService: ParameterService,
        private messageService: MessagesService,
        private router: Router,
        private dialog: MatDialog
    ) { }

    @Input() item: Application = new Application();

    isLoaded: boolean;
    env = environment;
    personDocuments: ApplicationPersonDocument[] = [];
    genders: Classifier[] = [];
    countries: Classifier[] = [];
    docIssuers: Classifier[] = [];
    educationInstitutions: Classifier[] = [];
    educationLevels: Classifier[] = [];
    educations: ApplicationEducation[] = [];
    educationAcquiredInPlaces: Classifier[] = [];
    cities: Territory[] = [];
    counties: Territory[] = [];
    parishes: Territory[] = [];
    villages: Territory[] = [];
    postalCodes: Territory[] = [];
    isEdit: boolean;
    isEditor: boolean;
    isHomines: boolean = false;
    currentYear: number;
    today: Date;
    maxBirthDate: Date;
    photoUrl: string;
    //homines
    myInstitution: Classifier;
    languageLevels = ['Beginner', 'Intermediate', 'Advanced', 'MotherTongue'];
    languageCertificates: Classifier[] = [];
    aux: {
        Languages: any[],
        OtherDocs: string,
        personDocuments: any[],
        education: {
            [key: number]: {
                attestations: any[],
                grades: any[],
                aicOpinions: any[],
                other: any[],
                personDataDiffer: any
            }
        }
    } = {
            Languages: [],
            OtherDocs: undefined,
            personDocuments: [],
            education: {}
        };
    personDataInfo: string;

    filteredDocIssuers: Classifier[] = [];
    filteredCountries: Classifier[] = [];
    filteredCitizenships: Classifier[] = [];

    minEducationYear: number = 1900;
    maxEducationYear: number = new Date().getFullYear() + 1;

    canTakeSnapshot: boolean;
    camPhotoStarted: boolean;
    maxFileUploadSize: number;
    fileUploadExtensions: string;
    showEduAicOpinion: boolean;
    under18Message: string;
    isAdmin: boolean;
    isPowerUser: boolean;

    get isUnder18(): boolean {
        let bd = Utils.ensureDate(this.item.Birthdate);
        return !!bd && Utils.getAge(bd) < 18;
    }

    get hasPassport(): boolean {
        return this.item.DocType === 'Passport';
    }

    get hasEid(): boolean {
        return this.item.DocType === 'eID';
    }

    get personCodeHidden(): boolean {
        return this.item.IsForeign && (this.app.auth.isApplicant() || this.app.auth.isAgent());
    }

    get personCodeReadOnly(): boolean {
        return this.app.auth.isApplicant() && 
            !this.item.SubmittedByRepresentative &&
            (this.app.auth.currentUser.accountType === 'OpenAuth' || this.app.auth.currentUser.accountType === 'BankLink');
    }

    get IsForeign(): boolean {
        return this.item.IsForeign;
    }

    get isResidentAdmission(): boolean {
        return this.item.IsRZ || this.item.IsUnitedRZ;
    }

    get formIsValid(): boolean {
        let controls = this.form.controls;
        const types = ['pattern', 'yearvalidator'];

        for (let c in controls) {
            for (let e in controls[c].errors) {
                if (types.indexOf(e) > -1) {
                    return false;
                }
            }
        }

        return true;
    }

    @ViewChild('form', { static: true }) private form: NgForm;
    @ViewChild('photoInput', { static: true }) private photoInput: ElementRef;

    private origItem: string;
    private origAux: string;
    private origEducations: string;
    private origPersonDocuments: string;

    private docIssuersSearchTerms = new Subject<string>();
    private citizenshipSearchTerms = new Subject<string>();
    private educationInstituionSearchTerms = new Subject<string>();

    private educationAcquiredInId_LV: string[] = [];

    private camRotation: number = 0;
    private eduAuxFiles: { attestation: File, grades: File, aicOpinion: File, other: File }[] = [];

    ngOnInit() {
        if (!this.item || !this.item.Id)
            return;

        this.today = new Date();
        this.currentYear = this.today.getFullYear();
        this.item = JSON.parse(JSON.stringify(this.item));
        this.isHomines = this.item.IsHomines;

        if (this.item.MyInstitution && this.item.MyInstitutionId) {
            this.myInstitution = {
                Id: this.item.MyInstitutionId,
                Code: '',
                LegacyCode: '',
                Type: 'EduInstitution',
                Value: this.item.MyInstitution,
                ValueEN: this.item.MyInstitution,
                ValueLV: this.item.MyInstitution,
                Payload: ''
            };
        }

        this.origItem = this.serializeForDiff(this.item);

        if (this.item.PhotoTimestamp) {
            this.photoUrl = this.service.getPhotoUrl(this.item.Id, this.item.PhotoTimestamp);
        }

            const enLanguage = 'English';
            let loading = this.app.showLoading();

        zip(
            this.service.getAuxData(this.item.Id),
            this.service.getEducations(this.item.Id),
            this.classifierService.get('Gender,DocIssuer,Country,EducationLevel,LanguageCertificate,EducationAcquiredIn'),
            this.parameterService.getValues(),
            this.service.getPersonDocuments(this.item.Id),
            this.messageService.getByCodes(['APPLICATION_PERSON_DATA_INFO', 'APPLICATION_UNDER18'])
        ).subscribe(data => {
            let aux: ApplicationAuxData[];
            let classifiers: Classifier[];
            let params: ParameterValue[];
            let messages: Message[];

            [aux, this.educations, classifiers, params, this.personDocuments, messages] = data;

            this.aux = this.parseAuxData(aux);

            if (this.item.IsForeign) {
                if (!this.aux.Languages.some(t => t.Language === enLanguage)) {
                    this.aux.Languages.unshift({
                        Language: enLanguage
                    });
                }

                this.aux.Languages.sort((a, b) => a.Id - b.Id);
            }

            if (!this.educations.length)
                this.addEducation();

            this.genders = classifiers.filter(t => t.Type === 'Gender');
            this.educationLevels = classifiers.filter(t => t.Type === 'EducationLevel');
            this.educationAcquiredInPlaces = classifiers.filter(t => t.Type === "EducationAcquiredIn");
            this.languageCertificates = classifiers.filter(t => t.Type === 'LanguageCertificate');
            this.docIssuers = this.filteredDocIssuers = classifiers.filter(t => t.Type === 'DocIssuer');
            this.countries = this.filteredCountries = this.filteredCitizenships = classifiers.filter(t => t.Type === 'Country');

            const educationAcquiredInLV = this.educationAcquiredInPlaces.filter(t => t.Code === "LV");
            if (educationAcquiredInLV) {
                let ids: string[] = educationAcquiredInLV.map(x => x.Id);
                this.educationAcquiredInId_LV.push(...ids);
            }

            let minAgeParam = params.find(t => t.Code === 'MinApplicantAge');
            let minAge = minAgeParam.Value ? +minAgeParam.Value : defaultMinApplicantAge;

            let camRotationParam = params.find(t => t.Code === 'PersonPhotoCameraRotation');
            if (camRotationParam)
                this.camRotation = parseInt(camRotationParam.Value);

            let maxFileUploadSizeParam = params.find(t => t.Code === 'ApplicationFileUploadMaxSize');
            this.maxFileUploadSize = maxFileUploadSizeParam.Value ? +maxFileUploadSizeParam.Value : defaultMaxFileUploadSize;

            let fileUploadExtensionsParam = params.find(t => t.Code === 'ApplicationFileUploadExtensions');
            this.fileUploadExtensions = '.'
                + (fileUploadExtensionsParam.Value ? fileUploadExtensionsParam.Value : defaultFileUploadExtensions)
                    .split(',').join(',.');

            this.showEduAicOpinion = params.find(t => t.Code === 'ApplicationShowEduAicOpinion').Value === '1';

            this.maxBirthDate = new Date(this.currentYear - minAge, this.today.getMonth(), this.today.getDate(), 0, 0, 0);

            if (!this.personDocuments.length)
                this.addPersonDocument();

            this.personDocuments.forEach(t => {
                t.Type = t.Type || 'Passport';
            });

            this.origPersonDocuments = this.serializeForDiff(this.personDocuments);

            let personDataInfo = messages.find(t => t.Code === 'APPLICATION_PERSON_DATA_INFO');
            if (personDataInfo)
                this.personDataInfo = this.app.translate.currentLang === 'en' ? personDataInfo.TextEN : personDataInfo.TextLV;

            let under18Message = messages.find(t => t.Code === 'APPLICATION_UNDER18');
            if (under18Message)
                this.under18Message = this.app.translate.currentLang === 'en' ? under18Message.TextEN : under18Message.TextLV;

            if (!this.aux.personDocuments.length)
                this.aux.personDocuments.push({ Type: 'PersonDocument', index: 1 });

            this.origAux = this.serializeForDiff(this.aux);
            this.origEducations = this.serializeForDiff(this.educations);

            this.isLoaded = true;
            this.app.hideLoading(loading);
        }, err => this.app.showLoadError(err));

        this.app.auth.user.subscribe(user => {
            this.isAdmin = this.app.auth.isAdmin();
            this.isPowerUser = this.isAdmin || this.app.auth.isPowerUser();
            this.isEditor = this.isPowerUser || this.app.auth.userIn([UserType.AdmClerk, UserType.ExamClerk]);
            this.canTakeSnapshot = this.isEditor || this.app.auth.isUnitedEnrollClerk();
        });

        const setupTerms = (t: Subject<string>) => t.pipe(debounceTime(300), distinctUntilChanged());

        setupTerms(this.docIssuersSearchTerms)
            .subscribe(term => this.filteredDocIssuers = this.docIssuers.filter(t => t.Value.toLowerCase().indexOf(term) > -1));

        setupTerms(this.citizenshipSearchTerms)
            .subscribe(term => this.filteredCitizenships = this.countries.filter(t => t.Value.toLowerCase().indexOf(term) > -1));

        setupTerms(this.educationInstituionSearchTerms).pipe(
            switchMap(term => term ? this.classifierService.search('EduInstitution', term + '') : of([]))
        ).subscribe(data => this.educationInstitutions = data);
    }

    showAuxAicOpinion(e: ApplicationEducation): boolean {
        return this.showEduAicOpinion &&
            (!e.EducationAcquiredInId || this.educationAcquiredInId_LV.indexOf(e.EducationAcquiredInId) === -1);
    }

    canDeactivate() {
        let diff = [
            this.differsFromOriginal(this.item, this.origItem),
            this.differsFromOriginal(this.aux, this.origAux),
            this.differsFromOriginal(this.educations, this.origEducations),
            this.differsFromOriginal(this.personDocuments, this.origPersonDocuments),
            this.differsFromOriginal(this.item, this.origItem),
        ];

        if (diff.indexOf(true) > -1) {
            let subj = new Subject<boolean>();
            this.app.confirm(this.app.translate.instant('unsavedDataConfimation'), result => {
                subj.next(result);
            });
            return subj.asObservable();
        }

        return of(true);
    }

    submit() {
        let loading = this.app.showLoading();
        let aux = <ApplicationAuxData[]>[
            {
                Type: 'OtherDocs',
                Data: this.aux.OtherDocs
            }
        ];

        this.aux.Languages.forEach(t => {
            let text2 = t.Level !== 'MotherTongue' ? t.Cert : undefined;

            aux.push(<ApplicationAuxData>{
                Type: 'Language',
                Data: t.Language,
                Text1: t.Level,
                Text2: text2,
                Text3: text2 ? t.Score : undefined
            });
        });


        if (!this.aux.personDocuments.some(pd => pd.Id || pd.fileName)) {
            // delete all
            aux.push(<ApplicationAuxData>{
                Id: -1,
                Type: 'PersonDocument'
            });
        } else {
            this.aux.personDocuments.forEach(t => {
                if (t.Id) {
                    // update
                    aux.push(<ApplicationAuxData>{
                        Id: t.Id,
                        Type: t.Type
                    });
                } else if (t.Binary !== undefined)
                    aux.push(<ApplicationAuxData>{
                        Type: t.Type,
                        Binary: t.Binary,
                        Text1: t.fileName,
                        Text3: t.index,
                        Text5: 'HybridCase'
                    });
            });
        }

        this.service.update(this.item).subscribe(appData => {
            zip(
                this.service.saveAuxData(this.item.Id, aux),
                this.service.saveEducations(this.item.Id, this.educations),
                this.service.savePersonDocuments(this.item.Id, this.personDocuments)
            ).subscribe(res => {
                this.origItem = this.serializeForDiff(this.item);
                this.origPersonDocuments = this.serializeForDiff(this.personDocuments);

                // save education aux when IDs are available
                let eduResult = res[1];

                this.educations.forEach(t => {
                    const prevId = t.Id;
                    const eduAux = this.aux.education[prevId];

                    if (eduResult[prevId]) {
                        // set real IDs
                        t.Id = eduResult[prevId];

                        const keys = this.generateEduAuxTypes(t.Id);

                        eduAux.attestations.forEach(x => x.Type = keys.attestation);
                        eduAux.grades.forEach(x => x.Type = keys.grades);
                        eduAux.aicOpinions.forEach(x => x.Type = keys.aicOpinion);
                        eduAux.other.forEach(x => x.Type = keys.other);
                        eduAux.personDataDiffer.Type = keys.personDataDiffer;
                        this.aux.education[t.Id] = eduAux;
                        delete this.aux.education[prevId];
                    }
                });

                this.origEducations = this.serializeForDiff(this.educations);
                this.origAux = this.serializeForDiff(this.aux);
                let allEduAux: any = [];

                for (let k in this.aux.education) {
                    let eduAux = this.aux.education[k];
                    let keys = this.generateEduAuxTypes(k);
                    let diff = eduAux.personDataDiffer;

                    eduAux.attestations
                        .concat(eduAux.grades)
                        .concat(eduAux.aicOpinions)
                        .concat(eduAux.other)
                        .forEach(t => {
                            if (t.Id) {
                                allEduAux.push({
                                    Id: t.Id,
                                    Type: t.Type
                                });
                            } else if (t.Binary !== undefined) {
                                allEduAux.push({
                                    Type: t.Type,
                                    Binary: t.Binary,
                                    Text1: t.fileName,
                                    Text3: t.index,
                                    Text5: 'HybridCase'
                                });
                            }
                        });

                    if (!eduAux.attestations.some(f => f.Id || f.Binary !== undefined))
                        allEduAux.push({ Id: -1, Type: keys.attestation });

                    if (!eduAux.grades.some(f => f.Id || f.Binary !== undefined))
                        allEduAux.push({ Id: -1, Type: keys.grades });

                    if (!eduAux.aicOpinions.some(f => f.Id || f.Binary !== undefined))
                        allEduAux.push({ Id: -1, Type: keys.aicOpinion });

                    if (!eduAux.other.some(f => f.Id || f.Binary !== undefined))
                        allEduAux.push({ Id: -1, Type: keys.other });

                    if (diff) {
                        allEduAux.push({
                            Id: diff.Id,
                            Type: keys.personDataDiffer,
                            Data: diff.value || false,
                            Text1: diff.text,
                            Text5: 'HybridCase'
                        });
                    } else {
                        allEduAux.push({ Id: -1, Type: keys.personDataDiffer });
                    }
                }

                this.service.saveAuxData(this.item.Id, allEduAux).subscribe(res => {
                    this.app.hideLoading(loading);

                    this.confirmSubmit(() => {
                        this.router.navigate([this.app.localize.translateRoute('/applications'), this.item.Number]);
                    });
                }, err => this.app.showSaveError(err));
            }, err => this.app.showSaveError(err));
        }, err => this.app.showSaveError(err));
    }

    editPhoto({ imageChangedEvent = undefined, imageBase64 = undefined }) {
        let dialogRef = this.dialog.open(PhotoEditDialogComponent, {
            disableClose: true,
            data: {
                imageChangedEvent: imageChangedEvent,
                imageBase64: imageBase64
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.photoInput.nativeElement.value = '';

            if (result) {
                this.saveProfilePic(result.base64);
            }
        });
    }

    deletePhoto() {
        this.app.confirm(this.app.translate.instant('applications_confirmDeletePhoto'), res => {
            if (res) {
                let loading = this.app.showLoading();
                this.service.deletePhoto(this.item.Id).subscribe(res => {
                    this.photoUrl = undefined;
                    this.app.hideLoading(loading);
                }, err => this.app.showDeleteError(err));
            }
        });
    }

    addPersonDocument() {
        let item = new ApplicationPersonDocument();
        item.ApplicationId = this.item.Id;
        item.Type = 'Passport';

        if (!this.personDocuments.length)
            item.IsMain = true;

        this.personDocuments.push(item);
    }

    removePersonDocument(item: ApplicationPersonDocument) {
        let index = this.personDocuments.indexOf(item);

        if (index > -1)
            this.personDocuments.splice(index, 1);

        if (this.personDocuments.length === 1)
            this.personDocuments[0].IsMain = true;
    }

    setPersonDocumentNumber(value, item: ApplicationPersonDocument) {
        item.Number = (value || '').toUpperCase();
    }

    setPersonDocumentIsMain(value, item: ApplicationPersonDocument) {
        this.personDocuments.forEach(t => t.IsMain = false);
        item.IsMain = value;
    }

    confirmDataTransfer() {
        if(!this.item.SubmittedByRepresentative) {
            this.app.confirm(this.app.translate.instant('applications_lblEducationDeletionWarning'), res => {
                if(res) {
                    this.clearEverythingForRepresentative();
                }
                else {
                    this.item.SubmittedByRepresentative = false;
                }
            });
        }
        else {
            this.revertRepresentativeChanges();
        }
    }

    revertRepresentativeChanges() {
        this.item.Name = this.item.RepresentativeName;
        this.item.Surname = this.item.RepresentativeSurname;
        this.item.PersonCode = this.item.RepresentativePersonCode;

        this.item.RepresentativeName = "";
        this.item.RepresentativeSurname = "";
        this.item.RepresentativePersonCode = "";
    }

    clearEverythingForRepresentative() {
        if(this.educations.length > 0) {
            this.removeEducation(this.educations[0], true);
        }

        this.item.RepresentativeName = this.item.Name;
        this.item.RepresentativeSurname = this.item.Surname;
        this.item.RepresentativePersonCode = this.item.PersonCode;

        this.item.Name = "";
        this.item.Surname = "";
        this.item.PersonCode = "";
        if(this.educations.length == 0)
            this.addEducation();
    }

    addEducation() {
        let item = new ApplicationEducation();
        item.Id = -this.getUniqueKey(item);
        item.TempId = item.Id.toString();
        item.ApplicationId = this.item.Id;
        item.SendFilesToDvs = true;

        if (!this.educations.length)
            item.IsSelected = true;

        this.educations.push(item);

        this.aux.education[item.Id] = {
            attestations: [],
            grades: [],
            aicOpinions: [],
            other: [],
            personDataDiffer: { value: false, text: '' }
        };
    }

    removeEducation(item: ApplicationEducation, ignoreWarning: boolean) {
        if (ignoreWarning) {
            let extSourcers: ApplicationEducation[] = [];
            extSourcers.push(...this.educations.filter(x => !!x.ExtSource));
            for (let i = 0; i < extSourcers.length; i++) {
                this.deleteEducation(true, this.educations.filter(x => x == extSourcers[i])[0]);
            }
        }
        else {
            this.app.confirm(this.app.translate.instant('confirmPersonEducationRemoval'), result =>
                this.deleteEducation(result, item)
            );
        }
    }

    deleteEducation(result: boolean, item: ApplicationEducation) {
        if (!result)
                return;
            let index = this.educations.indexOf(item);

            if (index > -1) {
                this.educations.splice(index, 1);
                this.aux.education[item.Id].attestations.length = 0;
                this.aux.education[item.Id].grades.length = 0;;
                this.aux.education[item.Id].aicOpinions.length = 0;
                this.aux.education[item.Id].other.length = 0;
                this.aux.education[item.Id].personDataDiffer = undefined;
            }
    }

    validatePersonDocuments(): string[] {
        let err = [];

        const t = this.app.translate;
        const required = this.IsForeign
            ? 1
            : this.personDocuments.length
                ? this.personDocuments[0].Type === 'eID' ? 2 : 1
                : 0;

        if (this.aux.personDocuments.filter(f => !!f.fileName).length < required) {
            err.push(Utils.formatString(t.instant('applications_notEnoughAuxFiles'), [required, t.instant('applicationFileTypePersonDocument')]));
        }

        return err;
    }

    validateEducation(item: ApplicationEducation): string[] {
        let err = [];

        let minYearTo = item.YearFrom || this.minEducationYear;
        let maxYearFrom = item.YearTo || this.maxEducationYear;

        if (item.YearFrom && (item.YearFrom + '').length === 4 && (item.YearFrom < this.minEducationYear || item.YearFrom > maxYearFrom))
            err.push(Utils.formatString(this.app.translate.instant('applications_eduYearFromRangeMustBe'), [this.minEducationYear, maxYearFrom]));

        if (item.YearTo && (item.YearTo + '').length === 4 && (item.YearTo < minYearTo || item.YearTo > this.maxEducationYear))
            err.push(Utils.formatString(this.app.translate.instant('applications_eduYearToRangeMustBe'), [minYearTo, this.maxEducationYear]));

        return err;
    }

    validateEducationDocuments(item: ApplicationEducation): string[] {
        let err = [];

        const t = this.app.translate;

        if (!this.IsForeign) {
            if (!this.aux.education[item.Id] || this.aux.education[item.Id].attestations.filter(f => !!f.fileName).length < 1)
                err.push(Utils.formatString(t.instant('applications_notEnoughAuxFiles'), [1, t.instant('applicationFileTypeEduAttestation')]));
            if (!this.aux.education[item.Id] || this.aux.education[item.Id].grades.filter(f => !!f.fileName).length < 1)
                err.push(Utils.formatString(t.instant('applications_notEnoughAuxFiles'), [1, t.instant('applicationFileTypeEduGrades')]));
        }

        return err;
    }

    eduIsSelectedChange(event, item: ApplicationEducation) {
        this.educations.forEach(t => t.IsSelected = false);
        item.IsSelected = event.checked;
    }

    getUniqueKey(item?: any): number {
        let key = Date.now();

        if (item) {
            if (!item['_index'])
                item['_index'] = key;

            return item['_index'];
        }

        return key;
    }

    diplomaNumberChange(event, item: ApplicationEducation) {
        item.DiplomaNumber = (event || '').toUpperCase();
    }

    addLanguageSkill() {
        this.aux.Languages.push({});
    }

    removeLanguageSkill(item: any) {
        if (this.aux.Languages.length <= 1)
            return;

        let index = this.aux.Languages.indexOf(item);

        if (index > -1)
            this.aux.Languages.splice(index, 1);
    }

    classifierDisplay(item: Classifier): string {
        return item ? item.Value : '';
    }

    classifierDisplayById(id: string, items: Classifier[]): string {
        return this.classifierDisplay(items.find(x => x.Id === id));
    }

    applicableEducationLevels(extSource: string): Classifier[] {
        return this.educationLevels.filter(c => extSource !== 'VIIS' || c.LegacyCode === '1' || c.LegacyCode === '2');
    }

    territoryDisplay(item: Territory): string {
        return item ? item.Name : '';
    }

    filterDocIssuers(term: string, item: ApplicationPersonDocument) {
        item.IssuerId = undefined;
        item.Issuer = term;
        this.docIssuersSearchTerms.next((term || '').toLowerCase());
    }

    filterCitizenships(term: string, document: ApplicationPersonDocument) {
        document.CitizenshipId = undefined;
        document.Citizenship = term;
        this.citizenshipSearchTerms.next((term || '').toLowerCase());
    }

    filterEducationInstitutions(term: string, item: ApplicationEducation) {
        item.Institution = term;
        item.InstitutionId = undefined;
        this.educationInstituionSearchTerms.next((term || '').toLowerCase());
    }

    docIssuerSelected(item: Classifier, doc: ApplicationPersonDocument) {
        doc.IssuerId = item.Id;
        doc.Issuer = item.Value;
    }

    citizenshipSelected(item: Classifier, document: ApplicationPersonDocument) {
        document.CitizenshipId = item.Id;
        document.Citizenship = item.Value;
    }

    educationInstitutionSelected(item: Classifier, institution: ApplicationEducation) {
        institution.Institution = item.Value;
        institution.InstitutionId = item.Id;
    }

    filterMyEducationInstitutions(term: string) {
        this.myInstitution = undefined;
        this.educationInstituionSearchTerms.next((term || '').toLowerCase());
    }
    educationMyInstitutionSelected(item: Classifier) {
        this.myInstitution = item;
        this.item.MyInstitutionId = item.Id;
    }

    showLanguageCertification(skill: { Language: string, Level: string }): boolean {
        return (skill.Language || '').toLowerCase() === 'english' && skill.Level !== 'MotherTongue';
    }

    takeSnapshot() {
        let dialogRef = this.dialog.open(CamPhotoDialogComponent, {
            data: {
                rotation: this.camRotation
            },
            disableClose: true
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result.image) {
                this.editPhoto({ imageBase64: result.image.src });
            } else if (result.error) {
                this.app.showError(result.error.toString());
            }
        });
    }

    setAuxFile(auxType: string, data: any) {
        if (!this.aux[auxType])
            this.aux[auxType] = {};

        this.aux[auxType].Binary = data ? data.file : null;
        this.aux[auxType].fileName = data ? data.file.name : null;
    }

    setEduAuxFile(eduFileType: string, index: number, data: any) {
        this.eduAuxFiles[index][eduFileType] = data ? data.file : null;
    }

    educationAcquiredInChanged(educationAcquiredInClassifierId: string, educationId: number) {
        if (this.educationAcquiredInId_LV.indexOf(educationAcquiredInClassifierId) !== -1) {
            this.aux.education[educationId].aicOpinions.length = 0;
        }
    }

    private confirmSubmit(callback: () => any) {
        let loading = this.app.showLoading();
        this.service.validate(this.item.Id).subscribe(errors => {
            this.app.hideLoading(loading);
            let personErr = errors.filter(t => (t.Group || '').indexOf('personData') === 0);
            for (let i = personErr.length - 1; i >= 0; i--) {
                if (personErr[i].Group === 'personData.contactAddress') personErr.splice(i, 1);
            }

            if (personErr.length) {
                let errText = parseValidationErrors(personErr, (t, params) => this.app.translate.instant(t, params));
                this.app.confirm({
                    text: `<p>${this.app.translate.instant('applications_applicationDataEmpty')}</p>
                        <div>${errText}</div>
                        <p>${this.app.translate.instant('applications_confirmPersonDataSave')}</p>`,
                    okText: this.app.translate.instant('confirm')
                }, result => {
                    result && callback();
                });
            } else {
                callback();
            }
        }, err => this.app.showLoadError(err));
    }

    private parseAuxData(data: ApplicationAuxData[]): any {
        if (!data)
            data = [];

        let parser = new AuxDataParser(data);
        let getFiles = (type: string) => {
            return data.filter(t => t.Type === type).map(t => {
                return {
                    Id: t.Id,
                    Type: t.Type,
                    fileName: t.Text1,
                    index: t.Text3,
                    url: this.service.getAuxFileUrl(t.Id)
                };
            });
        };

        let aux: any = {
            Languages: data.filter(t => t.Type === 'Language').map(t => {
                return {
                    Language: t.Data,
                    Level: t.Text1,
                    Cert: t.Text2,
                    Score: t.Text3
                }
            }),
            OtherDocs: parser.getText('OtherDocs'),
            personDocuments: getFiles('PersonDocument'),
            education: {}
        };

        this.educations.forEach(t => {
            let keys = this.generateEduAuxTypes(t.Id);
            let diff = parser.getByType(keys.personDataDiffer);

            aux.education[t.Id] = {
                attestations: getFiles(keys.attestation),
                grades: getFiles(keys.grades),
                aicOpinions: getFiles(keys.aicOpinion),
                other: getFiles(keys.other),
                personDataDiffer: {
                    Id: diff ? diff.Id : undefined,
                    value: diff && diff.Data === 'true',
                    text: diff ? diff.Text1 : ''
                }
            };
        });

        return aux;
    }

    private saveProfilePic(dataUrl: string) {
        let loading = this.app.showLoading();
        let base64 = dataUrl.substring('data:image/png;base64,'.length);

        this.service.savePhoto(this.item.Id, Utils.base64ToBlob(base64, 'image/jpeg')).subscribe(() => {
            this.photoUrl = this.service.getPhotoUrl(this.item.Id, Date.now());

            this.app.hideLoading(loading);
        }, err => this.app.showSaveError(err));
    }

    private generateEduAuxTypes(id: number | string): {
        attestation: string,
        grades: string,
        aicOpinion: string,
        other: string,
        personDataDiffer: string
    } {
        return {
            attestation: `EduAttestation_${id}`,
            grades: `EduGrades_${id}`,
            aicOpinion: `EduAicOpinion_${id}`,
            other: `EduOther_${id}`,
            personDataDiffer: `EduPersonDataDiffer_${id}`
        };
    }

    private serializeForDiff(obj: any) : string {
        if (obj instanceof Array) {
            const copy = JSON.parse(JSON.stringify(obj));
            this.removeTemporaryIndex(copy);
            return JSON.stringify(copy);
        }
        return JSON.stringify(obj);
    }

    private differsFromOriginal(obj: any, original: string): boolean {
        const actual = this.serializeForDiff(obj);
        return actual !== original;
    }

    private removeTemporaryIndex(obj: any[]) {
        for (let i in obj) {
            if (obj[i]._index)
                delete obj[i]._index;
        }
        return obj;
    }
}

@Component({
    template: `
    <h2 mat-dialog-title>{{'applications_editPhotoTitle' | translate}}</h2>
    <mat-dialog-content>
        <div class="crop-container">
            <image-cropper [imageChangedEvent]="imageChangedEvent"
                           [imageBase64]="imageBase64"
                           [maintainAspectRatio]="true"
                           [aspectRatio]="4/5"
                           [resizeToWidth]="800"
                           [format]="'png'"
                           [cropper]="cropper"
                           (imageCropped)="imageCropped($event)"></image-cropper>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-raised-button type="button" [mat-dialog-close]="result" color="primary">{{'ok' | translate}}</button>
        <button mat-button type="button" mat-dialog-close>{{'cancel' | translate}}</button>
    </mat-dialog-actions>`,
    styleUrls: ['./person.component.css']
})
export class PhotoEditDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<PhotoEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.imageChangedEvent = data.imageChangedEvent;
        this.imageBase64 = data.imageBase64;
        this.cropper = {};
    }

    get result() {
        return {
            base64: this.croppedImage?.base64,
            coords: this.cropper
        };
    }

    imageChangedEvent: any;
    imageBase64: string;
    croppedImage: any;
    cropper: any;

    imageCropped(image: any) {
        this.croppedImage = image;
    }
}

@Component({
    template: `
    <h2 mat-dialog-title>{{'applications_takePhoto' | translate}}</h2>
    <mat-dialog-content>
        <app-cam-photo [rotation]="rotation" (selected)="snapshotSelected($event)" (error)="onError($event)"></app-cam-photo>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-button type="button" mat-dialog-close>{{'cancel' | translate}}</button>
    </mat-dialog-actions>`,
    styleUrls: ['./person.component.css']
})
export class CamPhotoDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<CamPhotoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.rotation = data.rotation || 0;
    }

    rotation: number = 0;

    snapshotSelected(snapshot) {
        this.dialogRef.close({ image: snapshot });
    }

    onError(error) {
        this.dialogRef.close({ error: error });
    }
}
