import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Classifier, ClassifierType } from '../models/Classifier';

import { IProgramWorkplace, Program } from '../models/Program';
import { AppService } from '../services/app.service';
import { ClassifierService } from '../services/classifier.service';

@Component({
    selector: 'app-admission-program-edit',
    templateUrl: './program-edit.component.html'
})
export class ProgramEditComponent implements OnInit {
    constructor(
        private app: AppService,
        private classifiers: ClassifierService,
        private dialogRef: MatDialogRef<ProgramEditComponent>,
        @Inject(MAT_DIALOG_DATA) data: { program: Program, workplacesEnabled: boolean }
    ) {
        this.item = data.program;
        this.workplacesEnabled = data.workplacesEnabled;
    }

    readonly item: Program;
    readonly workplacesEnabled: boolean;

    workplaceOptions: Classifier[] = [];

    ngOnInit() {
        const loading = this.app.showLoading();
        this.classifiers.get(ClassifierType.ResidencyWorkplace).subscribe(data => {
            this.app.hideLoading(loading);
            this.workplaceOptions = data;
        }, err => this.app.showLoadError(err));
    }

    addWorkplace() {
        if (!this.item.Workplaces) {
            this.item.Workplaces = [];
        }

        this.item.Workplaces.push({
            Id: undefined,
            Name: undefined,
            Quota: undefined
        });
    }

    removeWorkplace(workplace: IProgramWorkplace) {
        const ix = this.item.Workplaces.indexOf(workplace);

        if (ix > -1) {
            this.item.Workplaces.splice(ix, 1);
        }
    }

    onWorkplaceChange(workplace: IProgramWorkplace) {
        workplace.Name = this.workplaceOptions.find(t => t.Id == workplace.Id)?.Value;
    }

    isWorkplaceAdded(workplace: Classifier): boolean {
        return this.item.Workplaces.some(t => t.Id == workplace.Id);
    }

    submit() {
        this.dialogRef.close(this.item);
    }
}
