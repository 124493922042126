<form #form="ngForm" (ngSubmit)="submit()">
    <h2 mat-dialog-title [translate]="'programs_editTitle'"></h2>
    <mat-dialog-content>
        <mat-form-field>
            <input matInput [(ngModel)]="item.Title" name="title" [placeholder]="'programs_lblTitle' | translate" required>
        </mat-form-field>

        <div class="cols">
            <div class="col-4">
                <mat-form-field>
                    <input matInput [(ngModel)]="item.Quota" name="quota" [placeholder]="'programs_lblQuota' | translate" required pattern="^0|([1-9][0-9]*)$">
                </mat-form-field>
            </div>
            <div class="col-8">
                <mat-form-field>
                    <input matInput [(ngModel)]="item.NotificationCode" name="notificationCode" [placeholder]="'programs_lblNotificationCode' | translate">
                </mat-form-field>
            </div>
        </div>

        <div class="checkbox-row">
            <mat-checkbox [(ngModel)]="item.AllowProlongation" name="allowProlongation">{{'programs_lblAllowProlongation' | translate}}</mat-checkbox>
        </div>

        <div class="checkbox-row">
            <mat-checkbox [(ngModel)]="item.AllowAdmission" name="allowAdmission">{{'programs_lblAllowAdmission' | translate}}</mat-checkbox>
        </div>

        <div class="checkbox-row">
            <mat-checkbox [(ngModel)]="item.AllowApplicantToApply" name="allowApplicantApply">{{'programs_lblAllowApplicantToApply' | translate}}</mat-checkbox>
        </div>

        <div class="checkbox-row" *ngIf="workplacesEnabled">
            <mat-checkbox [(ngModel)]="item.IsWorkplaceRequired" name="isWorkplaceRequired">{{'programs_lblIsWorkplaceRequired' | translate}}</mat-checkbox>
        </div>

        <section class="block" *ngIf="workplacesEnabled && item.IsWorkplaceRequired">
            <header class="block-header">
                <h3>{{'programs_medicalInstitutions' | translate}}</h3>
            </header>

            <div class="block-body">
                <div class="input-row" *ngFor="let n of item.Workplaces; let i = index">
                    <div class="cols">
                        <div class="col-8">
                            <mat-form-field>
                                <mat-select [(ngModel)]="n.Id" [placeholder]="'programs_lblWorkplace' | translate" name="workplace_{{i}}" required
                                            (selectionChange)="onWorkplaceChange(n)">
                                    <mat-option *ngFor="let opt of workplaceOptions" [value]="opt.Id"
                                                [disabled]="n.Id != opt.Id && isWorkplaceAdded(opt)">{{opt.Value}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-3">
                            <mat-form-field>
                                <input matInput [(ngModel)]="n.Quota" name="quota_{{i}}" [placeholder]="'programs_lblQuota' | translate" required
                                       pattern="^0|([1-9][0-9]*)$">
                            </mat-form-field>
                        </div>
                        <div class="col-1">
                            <button type="button" class="input-row-btn btn-icon" (click)="removeWorkplace(n)">
                                <i class="fa fa-minus"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="rows-action">
                    <button mat-raised-button (click)="addWorkplace()" type="button">
                        <i class="fa fa-plus"></i>
                        {{'add_institution' | translate}}
                    </button>
                </div>
            </div>
        </section>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-raised-button type="submit" color="primary" [disabled]="!form.valid">{{'save' | translate}}</button>
        <button mat-button mat-dialog-close>{{'cancel' | translate}}</button>
    </mat-dialog-actions>
</form>
