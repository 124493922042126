import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { GridComponentBase } from '../../GridComponentBase';
import { Interviewer } from '../../models/Program';
import { AppService } from '../../services/app.service';
import { AuthService } from '../../services/auth.service';
import { ProgramService } from '../../services/program.service';

@Component({
    selector: 'app-interviewersGrid',
    templateUrl: './interviewersgrid.component.html',
    styleUrls: ['./interviewersgrid.component.css']
})
export class InterviewersGridComponent extends GridComponentBase<Interviewer> implements OnInit {
    constructor(public app: AppService, private auth: AuthService,
        private route: ActivatedRoute, public service: ProgramService) {
        super(app);
    }

    private programId: number;

    sessionKey = 'x';
    data = {
        MotivationLetters: [
            { Url: '', Title: 'Letter 1' },
            { Url: '', Title: 'Letter 2' },
            { Url: '', Title: 'Letter 3' }
        ]
    };

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.programId = params['id'];
            if (this.programId) {
                this.service.getInterviewers(this.programId).subscribe(data => {
                    this.gridItems = data;
                }, err => this.app.showLoadError(err));
            }
        });
    }

    isValidGridItem(item: Interviewer): boolean {
        if (!item.SignatureName || !item.ShortName || !item.PersonCode) return false;
        if (!/^[0-9]{6}-?[0-9]{5}$/.test(item.PersonCode)) return false;

        return true;
    }

    private normalizeFields(item: Interviewer) {
        if (item.PersonCode && item.PersonCode.includes('-'))
            item.PersonCode = item.PersonCode.replace('-', '');
    }

    protected createGridItem(item: Interviewer): Observable<Interviewer> {
        this.normalizeFields(item);
        return this.service.createInterviewer(item);
    }

    protected updateGridItem(item: Interviewer): Observable<Interviewer> {
        this.normalizeFields(item);
        return this.service.updateInterviewer(item);
    }

    protected deleteGridItem(item: Interviewer): Observable<boolean> {
        const subj = new Subject<boolean>();

        const request = (force: boolean) => {
            this.service.deleteInterviewer(item.ProgramId, item.Id, force).subscribe(() => {
                subj.next();
            }, err => {
                if (!force) {
                    if (err.error && err.error.InterviewCount > 0 && this.auth.userIs('administrator')) {
                        // We cannot call subj.error here because it will complete the subject and ignore a second try error.
                        // Hide the loading manualy instead.
                        this.app.hideLoading();

                        this.app.confirm({
                            text: this.app.translate.instant('interviewers_forceDelete'),
                            okText: this.app.translate.instant('delete')
                        }, result => {
                            if (!result)
                                return;

                            request(true);
                        });
                    }
                } else {
                    subj.error(err);
                }
            });
        };

        request(false);

        return subj.asObservable();
    }

    protected createEmptyObject(): Interviewer {
        const item = new Interviewer();
        item.ProgramId = this.programId;
        return item;
    }
}
