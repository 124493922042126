export class Utils {
    /**
     * Replace tokens in a string.
     * @param {string} input
     * @param {string[]} replacements
     * @returns {string}
     */
    static formatString(input: string, replacements: any): string {
        replacements = [].concat(replacements);
        return input.replace(/{(\d+)}/g, (match, n) => replacements[n]);
    };

    /**
     * Convert a base64 string to a blob.
     * @param base64
     * @param mimeType
     * @returns {Blob}
     */
    static base64ToBlob(base64: string, mimeType: string = '') {
        let sliceSize = 1024;
        let byteChars = window.atob(base64);
        let byteArrays = [];

        for (let offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
            var slice = byteChars.slice(offset, offset + sliceSize);

            let byteNumbers = new Array(slice.length);

            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            let byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: mimeType });
    }

    static prettyFileSize(bytes: number): string {
        const div = 1024;
        const units = ['B', 'KB', 'MB', 'GB'];

        let unit = units[0];
        let size = bytes;
        let curr: number;

        while ((curr = size / div) > 1) {
            size = curr;
            units.shift();
        }

        return `${+size.toFixed(2)}${units.length ? units[0] : unit}`;
    }

    /**
     * Get the date object from the value. Useful when dealing with moment dates bound to material datepicker.
     * @param value
     */
    static ensureDate(value: any): Date {
        if (!value) return undefined;
        if (value instanceof Date) return value;
        if (value.toDate) return value.toDate();
        if (typeof value === 'string') return new Date(value);
    }

    static getDateString(value: any): string {
        let date = this.ensureDate(value);

        if (!date) return undefined;

        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    }

    static ensurePersonCode(value: string): string {
        if (/^[0-9]{6}-?[0-9]{5}$/.test(value)) {
            if (value.indexOf('-') === -1)
                return `${value.substr(0, 6)}-${value.substring(6, 11)}`;

            return value;
        }

        return undefined;
    }

    static getAge(birthdate: Date): number {
        let now = new Date();
        let bdY = birthdate.getFullYear();
        let years = now.getFullYear() - bdY;
        now.setFullYear(bdY);
        return now > birthdate ? years : years - 1;
    }

    static getFileNameWithoutExtension(fileName: string): string {
        return fileName.split('.').slice(0, -1).join('.');
    }

    /**
     * Sort collection of objects.
     * @param data
     * @param by Data property name or custom comparator
     * @param direction
     */
    static sort<T>(data: T[], by: string | ((a: any, b: any) => number), direction: 'asc' | 'desc'): T[] {
        const mod = direction == 'desc' ? -1 : 1;

        if (typeof by == 'string') {
            const prop = by;

            let isString = false;

            data.sort((a: T, b: T) => {
                if (isString || typeof a[prop] == 'string' || typeof b[prop] == 'string') {
                    isString = true;
                    return (a[prop] || '').localeCompare(b[prop] || '') * mod;
                }

                return (a[prop] - b[prop]) * mod;
            });
        } else {
            const fn = by as any;
            data.sort((a, b) => fn(a, b) * mod);
        }

        return data;
    }
}
